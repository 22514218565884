import $ from "jquery"
import { Dropdown } from "./dropdown"
import { selectOptions } from "./select.options"

/**
 * @extends Dropdown
 */
export class Select extends Dropdown {
  /**
   * @param { selectOptions } options
   */
  constructor(options) {
    super({ ...selectOptions, ...options })

    const $triggerValue = this.$trigger.querySelector(this.options.triggerValueSelector)
    this.$triggerValue = $triggerValue ? $triggerValue : this.$trigger

    this.$input = this.$container.querySelector(this.options.inputSelector)
    this.options = this.$dropdown.querySelectorAll(this.options.dropdownOptionSelector)

    this.isFilterStatusInput =
      this.$input.closest(".user-management-filter-panel") || this.$input.closest(".trips-filter-form-section")

    this.#init()
  }

  #init() {
    this.options.forEach(($option) => {
      $option.addEventListener("click", this.optionClickHandle.bind(this))
    })
    if (this.isFilterStatusInput) {
      this.$input.addEventListener("change", this.onChangeFiltersAndStatus)
    }
  }

  onChangeFiltersAndStatus = () => {
    console.log("filter or status was changed")
  }

  optionClickHandle(event) {
    const { currentTarget: $clickedOption } = event
    const newValue = $clickedOption.dataset?.selectValue
    const filteredValue = $clickedOption.querySelector(".js-search-filter-value")
    const hoverOption = $clickedOption.closest(".dropdown__hover-option")
    const hoverOptionText = hoverOption?.childNodes[0].textContent.trim()

    if (!newValue) {
      return console.warn("Cant append new value because value is invalid", this)
    }

    if (this.$input) {
      if (this.$input.classList.contains("datepicker-input")) {
        $(this.$input).data("dateRangePicker").clear()
      } // datepicker values
      this.$input.value = hoverOption ? `${hoverOptionText} - ${newValue}` : newValue
      const changeEvent = new Event("change")
      this.$input.dispatchEvent(changeEvent)
    }

    this.options.forEach(($option) => {
      $option.parentNode.classList.remove("_selected")
    })
    $clickedOption.parentNode.classList.add("_selected")
    this.$container.classList.add("_chosen")

    if (filteredValue) {
      this.$triggerValue.innerHTML = filteredValue.innerHTML
    } else {
      this.$triggerValue.innerHTML = hoverOption
        ? `${hoverOptionText} - ${$clickedOption.innerHTML.trim()}`
        : $clickedOption.innerHTML.trim()
    }

    this.close()
  }
}
