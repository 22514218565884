export class TravalersDropdownSearchUser {
  constructor(searchFieldElement){
    
    this.searchUserField = searchFieldElement
    if(!this.searchUserField) return

    this.searchUserInput = this.searchUserField.querySelector(".js-travalers-settings-user-searchinput")
    this.searchUserDropdown = this.searchUserField.querySelector(".js-travalers-settings-user-searchdropdown")
    if(!this.searchUserInput && !this.searchUserDropdown) return

    this.userOptions = this.searchUserDropdown.querySelectorAll(".js-travalers-settings-user-option")

    this.init()
  }

  closeActiveDropdown = () => {
    const activeDropdown = document.querySelector(".js-travalers-settings-user-searchdropdown.active");
    if (activeDropdown) activeDropdown.classList.remove("active");
  }

  openSearchDropdown = () => {
    this.closeActiveDropdown()
    this.searchUserDropdown.classList.add("active")
  }

  closeSearchDropdown = () => {
    this.searchUserDropdown.classList.remove("active")
  }

  outsideClickHandle = (e) => {
    if(!e.target.closest('.js-travalers-settings-user-searchfield')) {
      this.closeSearchDropdown()
    }
  }

  inputFilterHandle = (inputValue) => {
    if(!this.userOptions.length) return

    if(inputValue.length < 1){
      this.userOptions.forEach(userOption => {
        userOption.classList.remove("hidden")
      })
    }else{
      this.userOptions.forEach(userOption => {
        const userFilterNames = userOption.querySelectorAll(".js-travalers-settings-user-option-searchvalue")
        let optionErrorCounter = 0
        userFilterNames && userFilterNames.forEach(itemSearch => {
          const searchValue = itemSearch.innerHTML.toLowerCase()
          if (searchValue.indexOf(inputValue) < 0) {
            optionErrorCounter++
          }
        })
        if(optionErrorCounter >= userFilterNames.length){
          userOption.classList.add("hidden")
        }else userOption.classList.remove("hidden")
      })
    }
  }

  init() {
    this.searchUserInput.addEventListener("input", (e) => this.inputFilterHandle(e.target.value.toLowerCase()))
    this.searchUserInput.addEventListener("focus", this.openSearchDropdown)
    window.addEventListener('click', this.outsideClickHandle)
  }
  
  destroy() {
    this.searchUserInput.removeEventListener("input", this.inputFilterHandle);
    this.searchUserInput.removeEventListener("focus", this.openSearchDropdown);
    window.removeEventListener('click', this.outsideClickHandle);
  }
}