import { Toast } from "../../../components/toast"
import { scrollbarLock, scrollbarUnlock } from "../../../utils/scrollbar-locker"

export class UserSettings {
  constructor(wrap) {
    this.wrapper = wrap
    this.steps = [...wrap.querySelectorAll(".js-user-settings-step")]
    this.btns = wrap.querySelectorAll(".js-user-setting-btn")
    this.openTriggers = document.querySelectorAll(".js-user-settings-open-trigger")
    this.isOpen = this.setOpen()
    this.#init()
  }

  setOpen = () => {
    this.isOpen = this.wrapper.classList.contains("active")
  }

  handleBtnCLick = (event) => {
    const { goto, toastMessage, toastStatus } = event.currentTarget.dataset
    if (goto === "close") {
      this.steps.forEach((step) => step.classList.remove("active"))
      this.wrapper.classList.remove("active")
      scrollbarUnlock()
      if (toastMessage && toastStatus) {
        setTimeout(() => {
          new Toast({ position: ".header__content", text: toastMessage, type: toastStatus })
        }, 300)
      }
      this.setOpen()
    } else {
      this.steps.forEach((step) => step.classList[step.dataset.step === goto ? "add" : "remove"]("active"))
    }
  }

  handleOpenTriggerClick = (event) => {
    if (event.target.closest(".hotels-catalog-sidebar-section__content-checkbox")) {
      return
    }
    if (this.isOpen) return
    this.wrapper.classList.add("active")
    scrollbarLock()
    this.steps.forEach((step) => {
      if (step.dataset.step === "preview") {
        step.classList.add("active")
      }
    })
    this.setOpen()
  }

  handleBackdropClick = (event) => {
    const isBackdrop = event.target === this.wrapper
    if (isBackdrop) {
      this.wrapper.classList.remove("active")
      this.steps.forEach((step) => step.classList.remove("active"))
      scrollbarUnlock()
    }
    this.setOpen()
  }

  #init() {
    this.btns.forEach((btn) => btn.addEventListener("click", this.handleBtnCLick))
    this.openTriggers.forEach((trigger) => trigger.addEventListener("click", this.handleOpenTriggerClick))
    window.addEventListener("click", this.handleBackdropClick)
  }
}
