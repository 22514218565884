export const getCatalogActiveOptions = (groupSelector, isNodeElement = false) => {
  let sectionElement
  if(isNodeElement){
    sectionElement = groupSelector
  }else sectionElement = document.querySelector(groupSelector)

  if(!sectionElement) return

  const sectionClass = sectionElement.className.replace("hotels-catalog-sidebar-section", '').replace(' ', '')
  let groupInputs
  let catalogGroupData

  switch (sectionClass) {
    case 'sort_by': { // sort by section
      groupInputs = sectionElement.querySelectorAll(".hotels-catalog-sidebar-section__content input")
      groupInputs && groupInputs.forEach(input => {if(input.checked) catalogGroupData = input.value})
      break;
    } 
    case 'nightly_rate': { // nightly rate section
      const switcherCustom = sectionElement.querySelector(".js-global-switcher")
      catalogGroupData = []
      if(switcherCustom && switcherCustom.checked){
        groupInputs = sectionElement.querySelectorAll(".range-slider .range-slider-input")
        groupInputs && groupInputs.forEach(input => {catalogGroupData.push(input.value)})
      }else{
        groupInputs = sectionElement.querySelectorAll(".hotels-catalog-sidebar-section__content-checkbox input")
        groupInputs && groupInputs.forEach(input => {if(input.checked) catalogGroupData.push(input.value)})
      }
      break;
    }
    case 'booking-policy':
    case 'star-rating': 
    case 'meal-options': 
    case 'pet-friendly': 
    case 'amenities': { // booking-policy, star-rating, meal-options, pet-friendly, amenities sections
      catalogGroupData = []
      groupInputs = sectionElement.querySelectorAll(".hotels-catalog-sidebar-section__content-checkbox input")
      groupInputs && groupInputs.forEach(input => {if(input.checked) catalogGroupData.push(input.value)})
      break;
    }
    case 'hotel-name': { // hotel-name sections
      groupInputs = sectionElement.querySelector(".hotels-catalog-sidebar-section__content-input-field input")
      if(groupInputs) catalogGroupData = groupInputs.value
      break;
    }
    case 'near-by':
    case 'neighbourhoods':{ // near-by, neighbourhoods sections
      groupInputs = sectionElement.querySelector(".hotels-catalog-sidebar-section__dropdown-box input")
      if(groupInputs) catalogGroupData = groupInputs.value
      break;
    }
    case 'select-distance': { // select-distance sections
      groupInputs = sectionElement.querySelector(".hotels-catalog-sidebar-section__unit-switcher input")
      console.log(groupInputs.checked)
      if(groupInputs){
        if(groupInputs.checked){
          catalogGroupData = groupInputs.getAttribute("data-true")
        }else catalogGroupData = groupInputs.getAttribute("data-false")
      }
      break;
    }
    case 'search-radius': { // search-radius sections
      const distanceInput = document.querySelector(".hotels-catalog-sidebar-section.select-distance .hotels-catalog-sidebar-section__unit-switcher input")
      if(distanceInput){
        const distanceStatus = distanceInput.checked 
        if(distanceStatus){
          groupInputs = sectionElement.querySelector(".hotels-catalog-sidebar-section__dropdown-box[data-type='mi'] input")
          if(groupInputs) catalogGroupData = groupInputs.value
        }else{
          groupInputs = sectionElement.querySelector(".hotels-catalog-sidebar-section__dropdown-box[data-type='km'] input")
          if(groupInputs) catalogGroupData = groupInputs.value
        }
      }
      break;
    }
    default: {
      console.warn(`Sorry, this section is not exist`);
    }
  }

  console.log("response data:", catalogGroupData)
  return catalogGroupData
}


// examples for use ===>
// getCatalogActiveOptions('.hotels-catalog-sidebar-section.sort_by') // need to put hotels-catalog-sidebar section selector
// const Inputs = document.querySelectorAll(".hotels-catalog-sidebar-section.sort_by input")
// Inputs && Inputs.forEach(input => {
//   input.addEventListener("input", () => {
//     const Parent = input.closest(".hotels-catalog-sidebar-section")
//     getCatalogActiveOptions(Parent, true)
//   })
// })

export const setCatalogPriceRangePicker = (minValue, maxValue) => {
  if(!minValue || !maxValue) return
  const minInput = document.querySelector(".price-slider-box .range-slider__min-value")
  const maxInput = document.querySelector(".price-slider-box .range-slider__max-value")
  const eventInput = new Event("input")
  if(!minInput || !maxInput) return

  minInput.value = minValue
  maxInput.value = maxValue
  minInput.dispatchEvent(eventInput)
  maxInput.dispatchEvent(eventInput)
}

// examples for use ===>
// setCatalogPriceRangePicker(130, 650)

export const setCatalogResultByCategory = (inputSelector, countValue = 0, isNodeElement = false) => {
  let inputElement
  if(isNodeElement){
    inputElement = inputSelector
  }else inputElement = document.querySelector(inputSelector)

  if(!inputElement) return

  const inputParent = inputElement.closest(".hotels-catalog-sidebar-section__content-checkbox")
  if(!inputParent) return
  const resultsSpan = inputParent.querySelector(".results")
  if(resultsSpan) resultsSpan.innerHTML = '(' + countValue + ')'
}
// examples for use ===>
// setCatalogResultByCategory("#amenities_balcony", 133, false) // default use with input selector
// setCatalogResultByCategory(nodeElement, 133, true) // default use with node element


export const changeCatalogHotelNameCallback = (input) => {
  if(!input) return
  console.log("here will be a callback for hotel name input")
}

export const resetCatalogFilters = () => {
  const catalogSidebar = document.querySelector(".hotels-catalog-sidebar")
  if(!catalogSidebar) return
  const eventInput = new Event("input")
  const eventChange = new Event("change")

  const checboxesCatalog = catalogSidebar.querySelectorAll(".hotels-catalog-sidebar-section__content-checkbox input[type='checkbox']")
  checboxesCatalog && checboxesCatalog.forEach(checkbox => {
    if(checkbox.checked) {
      checkbox.checked = false
      // checkbox.dispatchEvent(eventInput)
    }
  })

  const switchersCatalog = catalogSidebar.querySelectorAll(".toggle-switch .js-global-switcher")
  switchersCatalog && switchersCatalog.forEach(switcher => {
    if(switcher.checked){
      switcher.checked = false
      switcher.dispatchEvent(eventChange)
    }
  })

  const fieldsCatalog = catalogSidebar.querySelectorAll(".hotels-catalog-sidebar-section__content-input-field input")
  fieldsCatalog && fieldsCatalog.forEach(field => {
    field.value = ''
    // field.dispatchEvent(eventInput)
  })

  const dropdownsCatalog = catalogSidebar.querySelectorAll(".hotels-catalog-sidebar-section__dropdown-box")
  dropdownsCatalog && dropdownsCatalog.forEach(dropdown => {
    const dropdownTrigger = dropdown.querySelector(".hotels-catalog-sidebar-section__dropdown-trigger")
    if(dropdownTrigger){
      dropdownTrigger.removeAttribute("data-selected")
      const triggerText = dropdownTrigger.querySelector(".trigger-text")
      if(triggerText) triggerText.innerHTML = 'Please Select'
    }
    const dropdwonInput = dropdown.querySelector(".js-single-dropdown-input")
    if(dropdwonInput) {
      dropdwonInput.value = ''
      // dropdwonInput.dispatchEvent(eventInput)
    }
    const activeOption = dropdown.querySelector(".hotels-catalog-sidebar-section__dropdown-item.active")
    if(activeOption) activeOption.classList.remove("active")
  })

  const rangeSlider = catalogSidebar.querySelector(".range-slider")
  if(rangeSlider){
    const minInput = rangeSlider.querySelector(".range-slider__min-value")
    const maxInput = rangeSlider.querySelector(".range-slider__max-value")
    if(minInput) {
      minInput.value = minInput.getAttribute("min")
      minInput.dispatchEvent(eventInput)
    }
    if(maxInput) {
      maxInput.value = maxInput.getAttribute("max")
      maxInput.dispatchEvent(eventInput)
    }
  }

  const radiosCatalog = catalogSidebar.querySelectorAll(".hotels-catalog-sidebar-section.sort_by .hotels-catalog-sidebar-section__content-checkbox input")
  radiosCatalog && radiosCatalog.forEach(radio => {
    if(radio.checked) {
      radio.checked = false
    }
  })
  if(radiosCatalog){
    radiosCatalog[0].checked = true
    radiosCatalog[0].dispatchEvent(eventInput)
  }
} 