export class SystemSettingsPolicyItemRemove {
  constructor() {
    this.itemPolicyes = document.querySelectorAll(".system-settings-policy-manuals-step__content .system-settings-policy-step-item")
    if(!this.itemPolicyes) return
    this.itemPolicyCounter = document.querySelector(".system-settings-policy-manuals-step__content .js-system-settings-setup-header-counter .value")

    this.init()
  }

  updateCountOfItems = () => {
    this.itemPolicyes = document.querySelectorAll(".system-settings-policy-manuals-step__content .system-settings-policy-step-item")
    if(this.itemPolicyCounter) this.itemPolicyCounter.innerHTML = this.itemPolicyes.length
  }

  init() {
    this.updateCountOfItems()
    this.itemPolicyes && this.itemPolicyes.forEach(policyItem => {
      const removeBtnPolicy = policyItem.querySelector(".system-settings-policy-step-item__remove")
      removeBtnPolicy && removeBtnPolicy.addEventListener("click", () => {
        policyItem.remove()
        this.updateCountOfItems()
      })
    })
  }
}

