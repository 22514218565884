export class SideBarTriggers {
  constructor(sidebar) {
    this.sidebar = sidebar
    this.opentTrigger = document.querySelector('.js-hotels-catalog-open-sidebar-trigger')
    this.closeTrigger = this.sidebar.querySelector('.js-gotels-catalog-close-sidebar-trigger')
    console.log(this);
    this.#init()
  }

  handleClose = (event) => {
    this.sidebar.classList.remove('active')
  }
  handleOpen = (event) => {
    this.sidebar.classList.add('active')
  }

  #init() {
    this.closeTrigger.addEventListener('click', this.handleClose)
    this.opentTrigger.addEventListener('click', this.handleOpen)
  }
}