/**
 * Show hidden elements as modals, popups, tooltips, etc.
 * In a first load webpage html elements loads before styles. That's all
 * elements with transition is jumping on a page.
 * @type { NodeListOf<HTMLElement> }
 * */
import { accordions } from "../../data/accordions"
import { addCreditCardInputs, approvalRulesInputs } from "../../data/user-search-inputs"
import { Accordion } from "./components/accordions/accordion"
import { accordionOptions } from "./components/accordions/accordion.options"
import { Dropdown } from "./components/dropdowns/dropdown"
import { Select } from "./components/dropdowns/select"
import { Form } from "./components/forms/form"
import { Modal } from "./components/modals/modal"
import { defaultModalOptions } from "./components/modals/modal.default"
import { Tabs } from "./components/tabs/tabs"
import { quickInviteTabsOptions, tripsTabsOptions } from "./components/tabs/tabs-options"
import TelInput from "./components/tel-input/tel-input"
import { ActionBtn } from "./modules/action-btn"
import { adjustElementPositionInView } from "./modules/adjust-element-position-in-view"
import { AvailableRoomToggle } from "./modules/available-room-toggle"
import { CheckoutHeaderFilters } from "./modules/checkout/header-filters"
import { changeCheckoutNameHandle, submitCheckoutHandle } from "./modules/checkout/helpers"
import { CheckoutModalForm } from "./modules/checkout/modal-forms"
import { checkoutOpenLoadingModal } from "./modules/checkout/open-loading-modal"
import { CheckoutRoomDetails } from "./modules/checkout/room-details"
import { datePickers } from "./modules/datepickers"
import { finalizeSetupNotification } from "./modules/finalize-setup-notification"
import { gallerySlider } from "./modules/gallery-slider"
import { HotelDetailRoomFiltration } from "./modules/hotel-details/room-filtration"
import { ShowMoreDetails } from "./modules/hotel-details/show-more"
import { changeCatalogHotelNameCallback, resetCatalogFilters } from "./modules/hotels-catalog/catalog-helpers"
import { EditSearchPopup } from "./modules/hotels-catalog/edit-search-popup"
import { DistanceSwitcher, HotelsDropdown, PriceRangePicker, ShareFormModal } from "./modules/hotels-catalog/main"
import { SearchAvailabilityFormModal } from "./modules/hotels-catalog/search-availability-modal"
import { SideBarTriggers } from "./modules/hotels-catalog/sidebar-triggers"
import MapHotel from "./modules/map/map"
import { ResetFilters } from "./modules/reset-filters"
import { ReviewRequired } from "./modules/review-required/review-required-heading-dropdown"
import { SearchFilter } from "./modules/searchbar/searchbar-filter-items"
import { searchBarSearchFunction } from "./modules/searchbar/searchbar-search"
import { notificationsDropdownFunction } from "./modules/notifications"
import { CheckAmountOfPersons } from "./modules/hotel-details/check-amount-of-persons"
import { TravalersDropdown } from "./modules/searchbar/searchbar-travalers"
import { searchbarValidation } from "./modules/searchbar/searchbar-validation"
import { SelectAllCheckbox } from "./modules/select-all-checkbox"
import { HotelReviewsSidebar } from "./modules/sidebars/hotel-reviews-sidebar"
import { OptionsItemHandler } from "./modules/sidebars/options-item-handler"
import { TripsSidebar } from "./modules/sidebars/trips-sidebar"
import { sidenavLinks } from "./modules/sidenav-links"
import { sortItemsByDragging } from "./modules/sort-items-by-dragging"
import { SwitchToggle } from "./modules/switch-toggler"
import initFormValidation from "./modules/system-settings/form-validation/main"
import { addUserFromSearch } from "./modules/system-settings/modules/add-user-from-search"
import { observeTypeBtn } from "./modules/system-settings/modules/guest-users/observe-type-btn"
import { SearchRecommendedStaysHandler } from "./modules/system-settings/modules/recommended-stays-handler"
import { userGroups } from "./modules/system-settings/modules/user-groups"
import { ContentToggler } from "./modules/toggle-content"
import { uploadFileField } from "./modules/upload-file/upload-file-field"
import { ActionsDropdown } from "./modules/user-management/user-settings/actions-dropdown"
import { UploadAvatar } from "./modules/user-management/user-settings/upload-avatar"
import { UserSettings } from "./modules/user-management/user-settings/user-settings"
import { useDynamicAdapt } from "./utils/dynamicAdapt"
import { StepperSidebar } from "./modules/sidebars/stepper-sidebar"
import { Loader } from "@googlemaps/js-api-loader"
import { TabSelector } from "./components/tab-selector/tab-selector"

const hiddenElements = document.querySelectorAll(".important_none")

window.mapsLoader = new Loader({
  apiKey: "AIzaSyCAQUdedax46R-vxIowCM_lOP5BzcJpZHo",
  version: "weekly",
  libraries: ["marker", "maps", "places"],
});

window.addEventListener("load", () => {
  // open modal at checkout
  checkoutOpenLoadingModal(window.location.search)

  hiddenElements.forEach(($el) => $el.classList.remove("important_none"))
  let header = document.querySelector(".header")

  // set vh height
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty("--header-height", `${header?.offsetHeight || 68}px`)

  new sidenavLinks() // highlight active link sidenav

  const form = document.querySelector(".js-form")
  if (form) {
    new Form()
  }

  const selects = document.querySelectorAll(".js-select")
  if (selects && selects.length) {
    selects.forEach(
      (select) =>
        new Select({
          container: select,
        }),
    )
  }

  // menus
  const dropdownMenus = document.querySelectorAll(".js-dropdown")
  dropdownMenus &&
    dropdownMenus.forEach((container) => {
      new Dropdown({ container })
    })

  // tabs
  if (document.querySelector(tripsTabsOptions.container)) {
    new Tabs(tripsTabsOptions)
  }

  const tripsStatusTabsOptions = {
    container: ".trips-status-tabs",
    itemTriggerSelector: ".trips-tabs__trigger",
    itemContentSelector: ".trips-tabs__content",
    activeClass: "_active",
    duration: 300,
    activeIndex: 0,
  }
  if (document.querySelector(tripsStatusTabsOptions.container)) {
    new TabSelector(tripsStatusTabsOptions)
  }

  const quickInviteTabsList = document.querySelectorAll(".quick-invite-tabs")
  quickInviteTabsList &&
  quickInviteTabsList.forEach((quickTabs) => {
    quickInviteTabsOptions.container = quickTabs
    new Tabs(quickInviteTabsOptions)
  })
  // tabs end

  const settingsForms = document.querySelectorAll(".js-system-settings-form")
  if (settingsForms.length) {
    initFormValidation(settingsForms)
  }

  const telInputs = document.querySelectorAll('input[type="tel"]')
  if (telInputs.length) {
    new TelInput(telInputs)
  }

  useDynamicAdapt() // rearrange dom elements with JS

  datePickers() // Date pickers

  // User Groups
  userGroups()

  // searchbar
  searchBarSearchFunction()
  const searchbarForms = document.querySelectorAll(".js-searchbar-form")
  searchbarForms && searchbarForms.forEach((searchbarForm) => searchbarValidation(searchbarForm))

  // travalers dropdown
  const travalersDropdowns = document.querySelectorAll(".js-travalers-settings-dropdown-field")
  travalersDropdowns && travalersDropdowns.forEach((travalersDropdown) => new TravalersDropdown(travalersDropdown))

  // system preferences search
  const searchForms = document.querySelectorAll(".js-system-preferences-search-form")
  if (searchForms.length) {
    searchForms.forEach((form) => new SearchRecommendedStaysHandler(form))
  }
  // notifications dropdown
  notificationsDropdownFunction()
  // handle click on user from search
  addUserFromSearch(approvalRulesInputs)
  addUserFromSearch(addCreditCardInputs)

  // enable sort items by dragging
  sortItemsByDragging()

  // content toggler
  const contentToToggle = document.querySelectorAll(".js-toggle-container")
  contentToToggle && contentToToggle.forEach((container) => new ContentToggler(container))

  // {# INITIALIZE MODALS IN main.js:
  const modalListID = [
    "#modal-delete-branch",
    "#modal-delete-branch-cancel",
    "#modal-delete-group",
    "#modal-delete-group-cancel",
    "#modal-delete-policy",
    "#modal-delete-payment-method",
    "#modal-cancelation",
    "#modal-hotels-catalog-share-property",
    "#modal-hotel-gallery",
    "#modal-room-unavaliable",
    "#modal-hotel-details-conditions",
    "#modal-hotel-details-amenitions",
    "#modal-hotel-travel-policy",
    "#modal-hotel-search-availability",
    "#modal-checkout-loading",
    "#modal-room-unavailable",
    "#modal-confirm-reservation",
    "#modal-reject-booking",
    "#modal-payment-error",
    "#modal-network-error",
    "#modal-delete-user",
    "#modal-user-role",
    "#modal-transfer-users",
    "#modal-delete-selected-user",
    "#modal-hotel-map",
    "#modal-delete-department"
  ]
  modalListID.forEach((modalItem) => {
    new Modal({ ...defaultModalOptions, container: modalItem })
  })
  // #}

  // Upload Inputs
  const uploadInputs = document.querySelectorAll(".js-upload-file-input")
  uploadInputs &&
    uploadInputs.forEach((uploadInput) => {
      new uploadFileField(uploadInput)
    })

  //price range picker
  const priceRangePickerBox = document.querySelector(".price-slider-box")
  if (priceRangePickerBox) {
    const toggler = document.querySelector(".hotels-catalog-sidebar-section__toggle")
    new PriceRangePicker(priceRangePickerBox, toggler)
  }

  // single dropdowns init
  const singleDropdowns = document.querySelectorAll(".js-single-dropdown-box")
  singleDropdowns.length && singleDropdowns.forEach((dropdown) => new HotelsDropdown(dropdown))

  // distance switcher
  const unitSwitcher = document.querySelector("#hotels_catalog_select_distance_unit")
  if (unitSwitcher) {
    new DistanceSwitcher(unitSwitcher)
  }

  // hotels catalog share property modal
  const sharePropertyForm = document.querySelector(".js-modal__content-share-form")
  if (sharePropertyForm) {
    new ShareFormModal(sharePropertyForm)
  }

  // hotels catalog search availability modal
  const searchAvailabilityForm = document.querySelector(".js-modal__content-search-availability-form")
  if (searchAvailabilityForm) {
    new SearchAvailabilityFormModal(searchAvailabilityForm)
  }

  // hotels catalog sidebar triggers
  const hotelsCatalogSidebar = document.querySelector('.js-hotels-catalog-sidebar')
  if(hotelsCatalogSidebar) {
    new SideBarTriggers(hotelsCatalogSidebar)
  }

  // hotel details triggers
  const hotelDetailsSection = document.querySelector('.js-hotel-details-property-details-section')

  if(hotelDetailsSection) {
    new ShowMoreDetails(hotelDetailsSection)
  }

  const personsWrappers = document.querySelectorAll('.js-available-rooms-item-sleeps')
  if(personsWrappers && personsWrappers.length) {
    personsWrappers.forEach(wrap => new CheckAmountOfPersons(wrap))
  }

  // Trips sidebar
  new TripsSidebar()
  // Trips options click handler
  const tripItems = document.querySelectorAll(".js-trip-item")
  tripItems && tripItems.forEach((container) => new OptionsItemHandler(container))

  // Search filter
  const searchbars = document.querySelectorAll(".js-search-filter")
  searchbars && searchbars.forEach((container) => new SearchFilter(container))

  // accordions
  accordions.forEach((accordionItemOptions) => {
    new Accordion({ ...accordionOptions, ...accordionItemOptions })
  })

  // action btn
  let globalActionBtns = document.querySelectorAll(".js-action-toggle-btn")
  globalActionBtns &&
    globalActionBtns.forEach((globalActionBtn) => {
      new ActionBtn(globalActionBtn)
    })

  // menu toggler
  const sidenav = document.querySelector(".sidenav")
  if (sidenav) {
    const sidenavMinimizeBtn = sidenav.querySelector(".sidenav-toggle-menu-btn")
    sidenavMinimizeBtn &&
      sidenavMinimizeBtn.addEventListener("click", () => {
        sidenav.classList.toggle("active")
        sidenavMinimizeBtn.classList.toggle("active")
        if (sidenavMinimizeBtn.classList.contains("active")) {
          sidenavMinimizeBtn.setAttribute("title", "Minimize menu")
        } else sidenavMinimizeBtn.setAttribute("title", "Expand menu")
      })
  }

  // hotel reviews sidebar
  new HotelReviewsSidebar()

  // Gallery Slider
  gallerySlider()

  // checkout header filters
  const filtersBox = document.querySelector(".js-checkout-filter-box")
  filtersBox && new CheckoutHeaderFilters(filtersBox)

  // checkout modal forms
  const checkoutModalForms = document.querySelectorAll(".js-modal__reservation-form")
  checkoutModalForms && checkoutModalForms.length & checkoutModalForms.forEach((form) => new CheckoutModalForm(form))

  // checkout rooms
  const roomDetails = document.querySelector(".details-block")
  roomDetails && new CheckoutRoomDetails(roomDetails)

  // review required
  const reviewRequiredWrap = document.querySelector(".js-review-required")
  reviewRequiredWrap && new ReviewRequired(reviewRequiredWrap)

  // reset filters
  const filters = document.querySelectorAll(".js-reset-filters")
  filters && filters.forEach((container) => new ResetFilters(container))

  // finalize setup notification
  finalizeSetupNotification()

  // hotel catalog edit search modal
  new EditSearchPopup()

  // user settings
  const userSettingsWrap = document.querySelector(".js-user-settings")
  userSettingsWrap && new UserSettings(userSettingsWrap)

  // upload avatar
  const uploadAvatarWraps = document.querySelectorAll(".js-user-settings-upload-avatar-wrap")
  if (uploadAvatarWraps && uploadAvatarWraps.length) {
    uploadAvatarWraps.forEach((item) => new UploadAvatar(item))
  }

  // user management actions dropdown
  new ActionsDropdown()

  // available room toggle event
  const availableRooms = document.querySelectorAll(".available-rooms-item")
  availableRooms && availableRooms.forEach((availableRoom) => new AvailableRoomToggle(availableRoom))

  // select all checkboxes init
  new SelectAllCheckbox()

  // switcher toggle actions class
  const switcherTogglers = document.querySelectorAll(".js-global-switcher")
  switcherTogglers && switcherTogglers.forEach((switcher) => new SwitchToggle(switcher))

  // observe type dropdown in Guest Users
  observeTypeBtn()

  // adjust element position if it overflows viewport
  adjustElementPositionInView()

  // reset catalog filters
  const resetCatalogSidebarFiletrsBtns = document.querySelectorAll(".js-hotel-catalog-sidebar-reset-btn")
  resetCatalogSidebarFiletrsBtns &&
    resetCatalogSidebarFiletrsBtns.forEach((btnReset) => {
      btnReset.addEventListener("click", resetCatalogFilters)
    })

  // hotel name catalog callback
  const hotelNameInput = document.querySelector(
    ".hotels-catalog-sidebar .hotels-catalog-sidebar-section__content-input-field input",
  )
  hotelNameInput && hotelNameInput.addEventListener("input", () => changeCatalogHotelNameCallback(hotelNameInput))

  // hotel name checkout callback
  const checkoutChangeName = document.querySelector(".checkout .checkout-header__title .value")
  checkoutChangeName && checkoutChangeName.addEventListener("keyup", (e) => changeCheckoutNameHandle(e))

  // checkout submit handle
  const checkoutSubmitBtn = document.querySelector(".checkout-submit-event-btn")
  checkoutSubmitBtn &&
    checkoutSubmitBtn.addEventListener("click", () => {
      setTimeout(() => {
        const parentModal = checkoutSubmitBtn.closest(".modal__reservation-form")
        if (parentModal) {
          const checkError = parentModal.querySelector(".modal__reservation-checkbox-box.error")
          if (!checkError) submitCheckoutHandle()
        }
      }, 200)
    })

  //switch design
  if(document.querySelector(".switch-design")) {
    const button = document.querySelector(".switch-design");
    const wrapper = document.querySelector(".wrapper");
    button.addEventListener("click",function(e){
      wrapper.classList.toggle('switch');
    });
  }

  //notification password page
  if(document.querySelector(".auth-page__section .toast.success")) {
    const notification = document.querySelector(".auth-page__section .toast.success");
    setTimeout(() => {
      notification.classList.remove('show')
    }, 5000)
  }

  //notification log in page
  if(document.querySelector(".auth-page__section .toast.error")) {
    const toast = document.querySelector(".auth-page__section .toast.error");
    const field = document.querySelector(".auth-page__section .form-field.is-required");
    const button = document.querySelector(".auth-page__section .auth-page__form-btn");
    button.addEventListener('click', function(){
      setTimeout(() => {
        if(field.classList.contains("_invalid")) {
          toast.classList.add("show")
        }
      }, 200)
    })
  }
  // hotel map
  if (document.querySelector("#hotel-map")) {
    new MapHotel()
  }

  // hotel details filtration
  new HotelDetailRoomFiltration()
})

// header label hide
const closeBtnLabel = document.querySelector(".header__company__label__close-btn")
const label = document.querySelector(".header__company__label")
if(document.querySelector(".header__company__label")) {
  closeBtnLabel.addEventListener('click', function(){
    label.classList.add('_hide')
  })
}


// Resize
window.addEventListener("resize", function () {
  let header = document.querySelector(".header")
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty("--header-height", `${header?.offsetHeight || 68}px`)
})
//stepper sidebar
new StepperSidebar()



