import { scrollbarToggle, scrollbarUnlock } from "../../utils/scrollbar-locker"
import { TravalersDropdownSearchUser } from "./searchbar-search-user"

export class TravalersDropdown {
  constructor(travalersDropdownFieldElement){
    
    this.travelDropdownField = travalersDropdownFieldElement
    if(!this.travelDropdownField) return

    this.dropdownTriggerBtn = this.travelDropdownField.querySelector(".js-travalers-settings-dropdown-trigger")
    this.travalersDropdown = this.travelDropdownField.querySelector(".js-travalers-settings-dropdown")
    if(!this.travalersDropdown || !this.dropdownTriggerBtn) return

    this.dropdownOverlay = this.travelDropdownField.querySelector(".js-travalers-settings-dropdown-overlay")
    this.removeRoomBtns = this.travalersDropdown.querySelectorAll(".js-travalers-settings-dropdown-room-remove")
    this.addRoomBtn = this.travalersDropdown.querySelector(".js-travalers-settings-dropdown-room-add")
    this.submitDropdownBtn = this.travalersDropdown.querySelector(".js-travalers-settings-dropdown-submit")
    this.warningSection = this.travalersDropdown.querySelector(".js-travalers-settings-dropdown-warning")

    // inputs
    this.travalersCountInput = this.travelDropdownField.querySelector(".js-travalers-allcount__input")
    this.roomsCountInput = this.travelDropdownField.querySelector(".js-travalers__room__input")
    this.guestsDetailInput = this.travelDropdownField.querySelector(".js-travalers-guests-detail__input")
    this.primaryUserInput = this.travelDropdownField.querySelector(".js-travalers-primary-user__input")
    if(!this.travalersCountInput || !this.roomsCountInput || !this.guestsDetailInput || !this.primaryUserInput) return
    
    // counters
    this.maxTravalers = 20
    this.maxRooms = 8
    this.countOfRooms = 1
    this.countOfTravalers = 0

    // data
    this.primaryUser = null
    this.guestInfoArray = []

    // search
    this.searchFields = this.travelDropdownField.querySelectorAll(".js-travalers-settings-user-searchfield")
    this.userOptions = this.travelDropdownField.querySelectorAll(".js-travalers-settings-user-option")

    // variable
    this.isInModal = this.travalersDropdown.closest(".modal__wrapper")

    this.init()
  }

  // handlers
  toggleTravalersDropdown = () => {
    this.dropdownTriggerBtn.style.pointerEvents = "none"
    setTimeout(()=>{this.dropdownTriggerBtn.removeAttribute("style")}, 300)
    if(this.travalersDropdown.classList.contains("active")){
      this.dropdownTriggerBtn.classList.remove("active")
      this.travalersDropdown.classList.remove("active")
      this.updateDropdownScreen()
      this.setDataToInputs()
      setTimeout(()=>{this.travalersDropdown.removeAttribute("style")}, 300)
    }else{
      this.travalersDropdown.style.display = 'block'
      setTimeout(()=>{
        this.dropdownTriggerBtn.classList.add("active")
        this.travalersDropdown.classList.add("active")
      },50)
    }
    setTimeout(this.changeContentVisibility, 300)
    if(window.innerWidth < 577){
      if(!this.isInModal){
        scrollbarToggle()
        if(this.dropdownOverlay) this.dropdownOverlay.classList.toggle("active")
      }
    }
  }

  closeTravalersDropdown = () => {
    this.dropdownTriggerBtn.classList.remove("active")
    this.travalersDropdown.classList.remove("active")
    setTimeout(()=>{this.travalersDropdown.removeAttribute("style")}, 300)
    this.updateDropdownScreen()
    this.setDataToInputs()
    this.changeContentVisibility()

    if(window.innerWidth < 577){
      if(!this.isInModal){
        scrollbarUnlock()
        if(this.dropdownOverlay) this.dropdownOverlay.classList.remove("active")
      }
    }
  }

  clickOutsideHandle = (e) => {
    if (!e.target.closest(".js-travalers-settings-dropdown-field") && this.travalersDropdown.classList.contains("active")) {
      this.closeTravalersDropdown()
    }
  }

  changeVisibilityOfRoomBtns = () => {
    if(this.countOfRooms == this.maxRooms){
      this.travalersDropdown.classList.add("hidden-add-room-btn")
    }else if(this.travalersDropdown.classList.contains("hidden-add-room-btn")) this.travalersDropdown.classList.remove("hidden-add-room-btn")
    
    if(this.countOfRooms < 2){
      this.travalersDropdown.classList.add("hidden-remove-room-btn")
    }else if(this.travalersDropdown.classList.contains("hidden-remove-room-btn")) this.travalersDropdown.classList.remove("hidden-remove-room-btn")
  }

  updateOrderRooms = () => {
    const dropdownRooms = this.travalersDropdown.querySelectorAll(".js-travalers-settings-dropdown__room")
    dropdownRooms && dropdownRooms.forEach((roomNumber, index) => {
      const roomNumberSpan = roomNumber.querySelector(".js-travalers-settings-dropdown__room-number .value")
      if(roomNumberSpan) roomNumberSpan.innerHTML = index + 1  
    })
  }

  getDataGuests = () => {
    this.guestInfoArray = []
    const allRooms = this.travalersDropdown.querySelectorAll(".js-travalers-settings-dropdown__room")
    if(!allRooms) return
    const visibleRooms = Array.from(allRooms).filter(element => !element.classList.contains('hidden'));
    visibleRooms.forEach(roomElement => {
      const usersChosen = roomElement.querySelectorAll(".js-travalers-settings-dropdown-usertab")
      if(!usersChosen) return
      const dataUsersByRoom = []
      usersChosen.forEach(user => {
        if(user.hasAttribute("data-user-id")){
          dataUsersByRoom.push(user.getAttribute("data-user-id"))
        }
      })
      this.guestInfoArray.push(dataUsersByRoom)
    })
  }

  // closeSearchDropdown = (childOfDropdown) => {
  //   if(!childOfDropdown) return
  //   const dropdownParent = childOfDropdown.closest(".js-travalers-settings-user-searchdropdown.active")
  //   if(dropdownParent) dropdownParent.classList.remove("active")
  // }

  removeUserBtnClick = (e) => {
    if(e.target.closest(".js-travalers-settings-user-remove-btn")){
      const itemToRemove = e.target.closest(".js-travalers-settings-dropdown-usertab")
      if(!itemToRemove) return
      this.removeUserFromList(itemToRemove)
    }else return
  }

  clearRoomHandle = (roomParent) => {
    if(!roomParent) return
    const selectedUsersInRoom = roomParent.querySelectorAll(".js-travalers-settings-dropdown-usertab")
    selectedUsersInRoom && selectedUsersInRoom.forEach(userToClean => {
      this.removeUserFromList(userToClean)
    })
  }

  showWarningsHandle = () => {
    if(!this.warningSection) return
    const valueToChange = this.warningSection.querySelector(".value")
    if(!valueToChange) return
    if(this.countOfRooms == this.maxRooms && this.countOfTravalers < this.maxTravalers){
      // error rooms
      valueToChange.innerHTML = this.warningSection.getAttribute("data-error-rooms")
      this.warningSection.classList.remove('hidden')
    }else if(this.countOfRooms < this.maxRooms && this.countOfTravalers == this.maxTravalers){
      // error travalers
      valueToChange.innerHTML = this.warningSection.getAttribute("data-error-travalers")
      this.warningSection.classList.remove('hidden')
      this.travalersDropdown.classList.add("hidden-add-room-btn")
    }else if(this.countOfRooms == this.maxRooms && this.countOfTravalers == this.maxTravalers){
      // error rooms and travalers
      valueToChange.innerHTML = this.warningSection.getAttribute("data-error-travalers-and-rooms")
      this.warningSection.classList.remove('hidden')
    }else{
      valueToChange.innerHTML = ''
      this.travalersDropdown.classList.remove("hidden-add-room-btn")
      this.warningSection.classList.add('hidden')
    }
  }

  changeContentVisibility = () => {
    const contentDiv = document.querySelector(".content")
    if(!contentDiv) return
    if(this.travalersDropdown.classList.contains("active")){
      contentDiv.style.overflow = 'visible' 
    }else if(contentDiv.hasAttribute("style")) contentDiv.removeAttribute("style")
  }
  // handlers end

  addRoomHandle = () => {
    const roomToUnhide = this.travalersDropdown.querySelector(".js-travalers-settings-dropdown__room.hidden")
    if(!roomToUnhide) return
    this.countOfRooms++
    this.changeVisibilityOfRoomBtns()
    roomToUnhide.classList.remove("hidden")
    this.showWarningsHandle()
  }

  removeRoomHandle = (e) => {
    const roomParent = e.target.closest(".js-travalers-settings-dropdown__room")
    if(!roomParent) return
    this.countOfRooms--
    this.changeVisibilityOfRoomBtns()
    roomParent.classList.add("hidden")
    const listParent = roomParent.parentNode
    listParent.appendChild(roomParent)
    this.clearRoomHandle(roomParent)
    this.updateOrderRooms()
    this.showWarningsHandle()
  }

  updateDropdownScreen = () => {
    const triggerScreenOptions = this.dropdownTriggerBtn.querySelectorAll(".js-travalers-settings-dropdown-trigger_option")
    triggerScreenOptions && triggerScreenOptions.forEach(triggerOption=>{
      const screenValue = triggerOption.querySelector(".value")
      const screenHolder = triggerOption.querySelector(".holder")
      if(!screenValue || !screenHolder) return

      if(triggerOption.hasAttribute("data-role")){
        if(triggerOption.getAttribute("data-role") == "rooms"){
          const rooms = this.travalersDropdown.querySelectorAll(".searchbar-travalers__room")
          let roomNotEmptyCount = 0 
          rooms && rooms.forEach(room => {
            const roomWithUser = room.querySelector(".searchbar-travalers__room-form-search-user")
            if(roomWithUser) roomNotEmptyCount++
          })
          screenValue.innerHTML = roomNotEmptyCount
        }else if(triggerOption.getAttribute("data-role") == "travalers"){
          screenValue.innerHTML = this.countOfTravalers
        }
      }

      if(parseInt(screenValue.innerHTML) > 1 || parseInt(screenValue.innerHTML) < 1){
        if(screenHolder.hasAttribute("data-plural")) screenHolder.innerHTML = screenHolder.getAttribute("data-plural")
      }else {
        if(screenHolder.hasAttribute("data-singular")) screenHolder.innerHTML = screenHolder.getAttribute("data-singular")
      }
    })
  }

  createUser = (userData, userID, listToAdd) => {
    if(!userData || !userID || !listToAdd ) return
    const userItem = document.createElement("div")
    userItem.classList.add("searchbar-travalers__room-form-search-user")
    userItem.classList.add("js-travalers-settings-dropdown-usertab")
    userItem.setAttribute("data-user-id", userID)
    const userParseData = JSON.parse(userData)
    if(this.primaryUser == null) {
      userItem.classList.add("_primary-user") 
      this.primaryUser = userID
      this.travalersDropdown.classList.remove("no-primary-user")
      this.travalersDropdown.classList.remove("invalid-search")
      this.primaryUserInput.value = this.primaryUser
      const event = new Event("input")
      this.primaryUserInput.dispatchEvent(event)
    }
    const userItemContent = `
      <span class="user-name">${userParseData.user_name}<b> primary</b></span>
      <button type="button" class="remove-user-btn js-travalers-settings-user-remove-btn">
        <svg class="icon icon-close">
          <use href="./icon/icons/icons.svg#close"></use>
        </svg>
      </button>
      <div class="searchbar-travalers__room-form-search-user-card">
        <div class="searchbar-travalers__room-form-search-user-card-avatar">${userParseData.user_nikname}</div>
        <div class="searchbar-travalers__room-form-search-user-card-info">
          <div class="searchbar-travalers__room-form-search-user-card-heading">
            <div class="searchbar-travalers__room-form-search-user-card-name">${userParseData.user_name}</div>
            <div class="searchbar-travalers__room-form-search-user-card-status">${userParseData.user_status}</div>
          </div>
          <div class="searchbar-travalers__room-form-search-user-card-email">${userParseData.user_email}</div>
        </div>
        <div class="searchbar-travalers__room-form-search-user-card-details">
          <div class="searchbar-travalers__room-form-search-user-card-level">${userParseData.user_level}</div>
          <div class="searchbar-travalers__room-form-search-user-card-company">
            <span class="flag">
              <img src="./svg/flags/${userParseData.user_country}.svg" alt="img" class="flag-img">
            </span>
            <span class="company">${userParseData.user_company}</span>
          </div>
        </div>
      </div>
    `
    userItem.innerHTML = userItemContent
    if(userItem.classList.contains("_primary-user")){
      listToAdd.prepend(userItem)
    }else listToAdd.append(userItem)
  }

  addUserToList = (userOption) => {
    if(this.countOfTravalers == this.maxTravalers) return
    if(!userOption) return
    const userSameOptions = this.travelDropdownField.querySelectorAll(`.js-travalers-settings-user-option[data-user-id='${userOption.getAttribute("data-user-id")}']`)
    userSameOptions && userSameOptions.forEach(sameOption => {sameOption.classList.add("checked-option")})
    const searchUserForm = userOption.closest(".js-travalers-settings-user-form")
    if(!searchUserForm) return
    const listToAddUser = searchUserForm.querySelector(".js-travalers-settings-user-list")
    if(!listToAddUser) return
    this.createUser(userOption.getAttribute("data-user"), userOption.getAttribute("data-user-id"), listToAddUser)
    this.countOfTravalers++
    this.showWarningsHandle()
  }

  removeUserFromList = (removeUserItem) => {
    if(!removeUserItem) return
    if(removeUserItem.classList.contains("_primary-user")){
      this.primaryUser = null
      this.travalersDropdown.classList.add("no-primary-user")
      this.primaryUserInput.value = ''
    }
    removeUserItem.style.display = 'none'
    const optionsToShow = this.travelDropdownField.querySelectorAll(`.js-travalers-settings-user-option[data-user-id='${removeUserItem.getAttribute("data-user-id")}']`)
    optionsToShow && optionsToShow.forEach(elOption => {
      elOption.classList.remove("checked-option")
    })
    setTimeout(()=>{removeUserItem.remove()}, 10)
    this.countOfTravalers--
    this.showWarningsHandle()
  }

  setDataToInputs = () => {
    this.getDataGuests()
    
    this.travalersCountInput.value = this.countOfTravalers // travalers all count input 
    const rooms = this.travalersDropdown.querySelectorAll(".searchbar-travalers__room")
      let roomNotEmptyCount = 0 
      rooms && rooms.forEach(room => {
        const roomWithUser = room.querySelector(".searchbar-travalers__room-form-search-user")
        if(roomWithUser) roomNotEmptyCount++
      })
    this.roomsCountInput.value = roomNotEmptyCount // rooms all count input
    this.guestsDetailInput.value = JSON.stringify(this.guestInfoArray) // detail guest data
    this.primaryUserInput.value = this.primaryUser // primary user data
  }

  submitDropdownHandle = () => {
    if(!this.primaryUser){
      this.travalersDropdown.classList.add("invalid-search")
    }else{
      this.travalersDropdown.classList.remove("invalid-search")
      this.updateDropdownScreen()
      this.setDataToInputs()
      this.closeTravalersDropdown()
    }
  }

  init() {
    this.dropdownTriggerBtn.addEventListener("click", this.toggleTravalersDropdown)
    this.addRoomBtn && this.addRoomBtn.addEventListener("click", () => {this.addRoomHandle()})
    this.removeRoomBtns && this.removeRoomBtns.forEach(removeRoomBtn => {
      removeRoomBtn.addEventListener("click", (e) => {this.removeRoomHandle(e)})
    })
    this.submitDropdownBtn && this.submitDropdownBtn.addEventListener("click", () => {this.submitDropdownHandle()})
    this.searchFields && this.searchFields.forEach(searchField => {new TravalersDropdownSearchUser(searchField)})
    this.userOptions && this.userOptions.forEach(addUserBtn => {
      addUserBtn.addEventListener("click", () => {this.addUserToList(addUserBtn)})
    })

    window.addEventListener('click', (e) => { 
      this.removeUserBtnClick(e)
      this.clickOutsideHandle(e)
    })
  }
}