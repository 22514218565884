export class SearchFilter {
  constructor(container, isItemClickHandler = true) {
    this.isSelect = container.classList.contains("js-select")
    this.isItemClickHandler = this.isSelect ? false : isItemClickHandler
    this.input = container.querySelector(".js-search-filter-input")
    this.resultsList = container.querySelector(".js-search-filter-list")
    this.clearBtn = container.querySelector(".js-search-filter-clear")
    this.resultsItems = this.resultsList.querySelectorAll(".js-search-filter-item")
    this.hiddenClass = "_hidden"

    this.init()
  }

  init = () => {
    this.input.addEventListener("input", this.filterResults)
    this.isItemClickHandler && this.resultsList.addEventListener("click", this.handleItemClick)
    this.clearBtn && this.clearBtn.addEventListener("click", this.clearInput)
  }

  filterResults = () => {
    const inputValue = this.input.value.toLowerCase()
    let hasMatchingItems = false

    this.resultsItems.forEach((item) => {
      const itemValue = item.querySelector(".js-search-filter-value").textContent.trim().toLowerCase()
      const isMatching = itemValue.includes(inputValue)

      // item.style.display = isMatching ? "block" : "none"
      item.classList[isMatching ? "remove" : "add"]("_hidden")
      if (isMatching) {
        hasMatchingItems = true
      }
    })

    if (!this.isSelect) {
      this.resultsList.classList.toggle(this.hiddenClass, !inputValue || !hasMatchingItems)
    }
  }

  handleItemClick = (event) => {
    const clickedItem = event.target.closest(".js-search-filter-item")
    if (!clickedItem) return

    const itemValue = clickedItem.querySelector(".js-search-filter-value").textContent.trim()
    this.input.value = itemValue

    if (!this.isSelect) {
      this.resultsList.classList.add(this.hiddenClass)
    }
  }

  clearInput = () => {
    this.input.value = ""
    this.filterResults()
  }
}
