import { Loader } from "@googlemaps/js-api-loader"
import makePopup from "./popup"
// import makeMarker from "./marker"
import { setAspectRatio } from "./_set-aspect-ratio"

export default class MapHotel {
  constructor() {
    this.mapNode = document.querySelector("#hotel-map")
    this.map = null
    this.initialMapPosition = {
      lat: 25.86053,
      lng: -80.12019,
    }
    this.styleArray = [
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "blue",
            weight: {
              expression: "interpolate[linear, zoom, 5, 1, 15, 3]", // Adjust width based on zoom level
            },
          },
        ],
      },
      // Add more data-driven style rules
    ]

    this.#init()
  }

  // #API_KEY = "AIzaSyD62J9Me6r438iyXmAPoNYJF3BS43Ic704"
  #MAP_ID = "f5df71640b1063a5"

  #init() {
    if (this.mapNode) {
      if (window.innerWidth <= 768) {
        setAspectRatio()
      }
      window.addEventListener("resize", (event) => {
        if (window.innerWidth <= 768) {
          setAspectRatio()
        }
      })

      const loader = window.mapsLoader

      loader.load().then(({ maps }) => {
        this.map = new maps.Map(this.mapNode, {
          center: this.initialMapPosition,
          zoom: 14,
          mapId: this.#MAP_ID,
          language: "en",
          disableDefaultUI: true, // controls on map
          styles: this.styleArray,
        })

        this.#addMarker(maps)
      })
    }
  }

  #addMarker(maps) {
    const parser = new DOMParser()
    const iconSvgStr = `<svg width="25" height="32" fill="none" xmlns="http://www.w3.org/2000/svg" class="map-marker"><path class="icon-body" d="M24.09 12.045c0 9.644-10.889 18.577-10.889 18.577-.636.521-1.677.521-2.313 0 0 0-10.888-8.933-10.888-18.577C0 5.393 5.393 0 12.045 0s12.044 5.393 12.044 12.045Z" fill="#304659"/><path class="icon-circle" d="M12.044 18.104a6.067 6.067 0 0 1-6.06-6.06 6.067 6.067 0 0 1 6.06-6.06 6.067 6.067 0 0 1 6.06 6.06 6.067 6.067 0 0 1-6.06 6.06Z" fill="#fff"/></svg>`
    const iconSvg = parser.parseFromString(iconSvgStr, "image/svg+xml").documentElement

    const markerElement = new maps.marker.AdvancedMarkerElement({
      position: this.initialMapPosition,
      map: this.map,
      content: iconSvg,
      title: "Hotel Location",
    })

    const infoWindow = new maps.InfoWindow({
      content: makePopup(),
    })
    infoWindow.open({
      anchor: markerElement,
      map: this.map,
    })
  }
}
