import { dummyData } from "../../../../data/dummy-data"

export class UserSearchHandler {
  constructor(inputSelector, resultsPosition, onSearchItemClick, context) {
    this.context = context
    this.searchInput = document.querySelector(inputSelector)
    this.resultsPosition = resultsPosition
    this.onSearchItemClick = onSearchItemClick
    this.searchResults = this.createSearchResults()

    if (!this.searchInput) return

    this.init()
  }

  init() {
    this.searchInput.addEventListener("input", this.handleSearchQuery.bind(this))
    document.addEventListener("click", this.handleClickOutside.bind(this))
  }

  handleSearchQuery() {
    const searchValue = this.searchInput.value.trim()
    this.searchInput.nextElementSibling.style.display = "block"

    if (this.context) {
      const isInputEmpty = searchValue === ""
      let isAllFieldsFilled = true

      this.context.form.classList.toggle("_disabled", isInputEmpty)

      this.context.requiredInputs.forEach((input) => {
        if (input.value.trim() === "") {
          isAllFieldsFilled = false
          return
        }
      })

      this.context.submitButton.disabled = isInputEmpty || !isAllFieldsFilled
    }

    if (searchValue !== "") {
      const filteredData = this.filterData(searchValue)
      this.renderResults(filteredData)
    } else {
      this.clearSearchResults()
    }
  }

  filterData(searchQuery) {
    const searchTerm = searchQuery.toLowerCase()
    const filteredResults = dummyData.filter((item) => {
      const { name, email, rank } = item
      const lowerCaseName = name.toLowerCase()
      const lowerCaseEmail = email.toLowerCase()
      const lowerCaseRank = rank.toLowerCase()

      return (
        lowerCaseName.includes(searchTerm) || lowerCaseEmail.includes(searchTerm) || lowerCaseRank.includes(searchTerm)
      )
    })

    return filteredResults
  }

  renderResults(results) {
    this.searchResults.innerHTML = ""

    results.forEach((result) => {
      const userItem = this.createUserItem(result)

      this.searchResults.appendChild(userItem)

      if (this.onSearchItemClick) {
        userItem.addEventListener("click", () => {
          this.onSearchItemClick(userItem)
          this.clearSearchResults()
        })
      }
    })
  }

  createSearchResults() {
    const searchInputContainer = this.searchInput?.parentNode

    if (!searchInputContainer) return

    const searchResultsEl = document.createElement("ul")
    searchResultsEl.classList.add("system-settings-users-search-results", this.resultsPosition)
    searchInputContainer.appendChild(searchResultsEl)

    return searchResultsEl
  }

  createUserItem(user) {
    const userItem = document.createElement("li")
    userItem.classList.add("user-groups-add-users-list__item")
    userItem.setAttribute("data-value", user.name)
    userItem.setAttribute("data-id", user.id)

    const userContent = `<div class="quick-invite-tabs-item-user">
        <div class="quick-invite-tabs-item-user__initials">
          ${user.initials}
        </div>
        <div>
          <div>
            <b>${user.name}</b>
            <span class="quick-invite-tabs-item-user__rank">${user.rank}</span>
          </div>

          <div class="quick-invite-tabs-item-user__email">${user.email}</div>
        </div>
      </div>

      <div class="quick-invite-tabs-item-company-info">
        <div class="quick-invite-tabs-item-user__position">${user.position}</div>
        <div class="quick-invite-tabs-item-company-info__company">
          <img src="./svg/flags/us.svg" alt="img">
          ${user.company}
        </div>
      </div>`

    userItem.innerHTML = userContent

    return userItem
  }

  clearSearchResults() {
    this.searchResults.innerHTML = ""
    this.searchResults.style.display = "none"
  }

  handleClickOutside(event) {
    if (!this.searchInput.contains(event.target)) {
      this.searchInput.nextElementSibling.style.display = "none"
    } else {
      this.searchInput.nextElementSibling.style.display = "block"
    }
  }
}

export default UserSearchHandler
