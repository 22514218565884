import { initValidationFieldsState, updateValidationState } from "./_state-actions"
import { isFormValid, removeError } from "./_validation-actions"

export function handleDropdownBackdropClick(context, event) {
  const dropdowns = Object.values(context.formState).filter((item) => item.type === "dropdown")
  dropdowns.forEach((dropdown) => {
    if (
      !event.target.closest(".js-system-settings__dropdown-trigger") &&
      !event.target.closest(".js-system-settings__input-dropdown")
    ) {
      dropdown.input_dropdown.classList.remove("active")
      dropdown.trigger.classList.remove("active")
    }
  })
}

export const handleDialcodeChange = (targetElement, inputName, context) => {
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList" && mutation.target === targetElement) {
        context.formState[inputName].codeValue = targetElement.innerText
        const value = context.formState[inputName].value
        context.formState[inputName].full_value = context.formState[inputName].codeValue + value
        updateValidationState(context)
        removeError(context.formState[inputName].input, context)
        isFormValid(context)
      }
    }
  })
  const config = { characterData: true, subtree: true, childList: true }
  observer.observe(targetElement, config)
}

export const handleDropdownTriggerClick = (type, context) => {
  const nonActiveDropdownsKeys = Object.keys(context.formState).filter(
    (key) => key.includes("dropdown") && key !== type,
  )
  nonActiveDropdownsKeys.forEach((key) => {
    context.formState[key].trigger.classList.remove("active")
    context.formState[key].input_dropdown.classList.remove("active")
  })
  context.formState[type].trigger.classList.toggle("active")
  context.formState[type].input_dropdown.classList.toggle("active")
}

export const handleDropdownListClick = (event, type, currency, rating, context) => {
  
  const timelineLI = event.target.querySelectorAll(".timeline-li")
  if (event.target.nodeName !== "LI" && !timelineLI.length) return

  let currentLi
  if(timelineLI.length){ // if its office dropdown click handle
    timelineLI.forEach(elLI => {
      currentLi = elLI

      if (currentLi.classList.contains("js-system-settings__dropdown-list")) return
      const current = context.formState[type]
      let value = null

      if (currency === "true") {
        current.trigger.classList.add("filled-currency")
      } else if (rating === "true") {
        current.trigger.classList.add("filled-rating")
      } else current.trigger.classList.add("filled")

      if (currentLi.hasAttribute("data-id")) {
        value = currentLi.getAttribute("data-id")
      } else {
        value = currentLi.getAttribute("data-value")
      }

      // handle office dropdown list click
      if (currentLi.getAttribute("data-item-type")) {
        value.length && event.target.closest(".system-settings-form__group-field").classList.remove("invalid")
        handleOfficeDropdownListClick(current, currentLi, value)
        // handle office dropdown list click end
      } else {
        value.length && event.target.closest(".system-settings-form__group-field").classList.remove("invalid")

        if (currency === "true" || rating === "true") {
          current.valueContainer.innerHTML = currentLi.innerHTML
        } else current.valueContainer.innerHTML = currentLi.getAttribute("data-value")

        current.selectedItemNode && current.selectedItemNode.classList.remove("selected")
        currentLi.classList.add("selected")
        current.selectedItemNode = currentLi
        if (currentLi.hasAttribute("data-id")) {
          current.value = currentLi.getAttribute("data-id")
        } else {
          current.value = currentLi.getAttribute("data-value")
        }
        current.trigger.classList.remove("active")
        current.input_dropdown.classList.remove("active")
        current.input.value = value
        const changeEvent = new Event("input")
        current.input.dispatchEvent(changeEvent)
      }

      const { open, hide } = currentLi.dataset
      if (open && hide) {
        showDropdownTrigger(open, hide, context)
      }

      updateValidationState(context)
      isFormValid(context)
    })
  }else{ // other dropdowns click handle
    currentLi = event.target

    if (currentLi.classList.contains("js-system-settings__dropdown-list")) return
    const current = context.formState[type]
    let value = null

    if (currency === "true") {
      current.trigger.classList.add("filled-currency")
    } else if (rating === "true") {
      current.trigger.classList.add("filled-rating")
    } else current.trigger.classList.add("filled")

    if (currentLi.hasAttribute("data-id")) {
      value = currentLi.getAttribute("data-id")
    } else {
      value = currentLi.getAttribute("data-value")
    }

    // handle office dropdown list click
    if (currentLi.getAttribute("data-item-type")) {
      value.length && event.target.closest(".system-settings-form__group-field").classList.remove("invalid")
      handleOfficeDropdownListClick(current, currentLi, value)
      // handle office dropdown list click end
    } else {
      value.length && event.target.closest(".system-settings-form__group-field").classList.remove("invalid")

      if (currency === "true" || rating === "true") {
        current.valueContainer.innerHTML = currentLi.innerHTML
      } else current.valueContainer.innerHTML = currentLi.getAttribute("data-value")

      current.selectedItemNode && current.selectedItemNode.classList.remove("selected")
      currentLi.classList.add("selected")
      current.selectedItemNode = currentLi
      if (currentLi.hasAttribute("data-id")) {
        current.value = currentLi.getAttribute("data-id")
      } else {
        current.value = currentLi.getAttribute("data-value")
      }
      current.trigger.classList.remove("active")
      current.input_dropdown.classList.remove("active")
      current.input.value = value
      const changeEvent = new Event("input")
      current.input.dispatchEvent(changeEvent)
    }

    const { open, hide } = currentLi.dataset
    if (open && hide) {
      showDropdownTrigger(open, hide, context)
    }

    updateValidationState(context)
    isFormValid(context)
  }
}

// handle office dropdown list click
function handleOfficeDropdownListClick(current, currentLi, value) {
  const officesList = current.parent.querySelector(".dropdown-office-list")
  const selectedOfficesQty = current.parent.querySelector(".dropdown-office-selected-offices span")
  const inputField = current.input

  if (currentLi.classList.contains("selected")) {
    currentLi.classList.remove("selected")
    console.log(value)
    const items = officesList.querySelectorAll(".dropdown-office-list__item")
    items.forEach((item) => {
      if (item.hasAttribute("data-id")) {
        if (item.getAttribute("data-id") === value) {
          item.remove()
        }
      } else {
        if (item.getAttribute("data-value") === value) {
          item.remove()
        }
      }
    })

    // Remove the value from the input field value
    handleOfficeDropdownInputValueChange(inputField, value)
    // inputField.value = inputField.value
    //   .split(", ")
    //   .filter((item) => item !== value)
    //   .join(", ")
  } else {
    currentLi.classList.add("selected")

    const elem = `<li class="dropdown-office-list__item" 
      ${currentLi.hasAttribute("data-id") ? `data-id=${currentLi.getAttribute("data-id")}` : ""}
      ${currentLi.hasAttribute("data-value") ? `data-value='${currentLi.getAttribute("data-value")}'` : ""}
      >
				<p>${currentLi.getAttribute("data-value")}</p>
				<button class="dropdown-office-list__remove-btn" type="button">
          <svg class="icon icon-close-sidebar ">
            <use href="./icon/icons/icons.svg#close-sidebar"></use>
          </svg>
        </button>
			</li>`

    officesList.insertAdjacentHTML("beforeend", elem)

    // append the new value to the input field value
    inputField.value += (inputField.value ? ", " : "") + value
  }
  const eventInput = new Event("input") 
  inputField.dispatchEvent(eventInput)

  const selectedOfficesLength = officesList.querySelectorAll("li").length
  const isSingleOffice = selectedOfficesLength == 1
  const isListEmpty = selectedOfficesLength < 1

  officesList.classList[isListEmpty ? "remove" : "add"]("_visible")

  selectedOfficesQty.innerHTML = isSingleOffice ? `${selectedOfficesLength} office` : `${selectedOfficesLength} offices`
}
// handle office dropdown list click end

// handle office dropdown input value change
// TODO: REFACTOR
export function handleOfficeDropdownInputValueChange(input, value) {
  console.log(input, value)
  input.value = input.value
    .split(", ")
    .filter((item) => item !== value)
    .join(", ")
}
// handle office dropdown input value change end

function showDropdownTrigger(open, hide, context) {
  const toOpen = context.formState[`${open}_dropdown`]
  const toHide = context.formState[`${hide}_dropdown`]
  toOpen.parent.classList.remove("hidden")
  toOpen.value = ""
  toOpen.validation = true
  toHide.validation = false
  toHide.parent.classList.add("hidden")
  initValidationFieldsState(context)
}

export function handleDisabledDropdown(primaryDropdown, secondaryDropdown, mainCallback) {
  const primaryDropdownSelector = document.querySelector(`[data-type="${primaryDropdown}"] > button`)
  if (!primaryDropdownSelector) return

  const secondaryDropdownSelector = document
    .querySelector(`[data-type="${secondaryDropdown}"]`)
    .parentNode.querySelectorAll("button")

  const config = { subtree: true, attributes: true, attributeFilter: ["class"] }

  observeDropdown(primaryDropdownSelector)
  observeDropdown(secondaryDropdownSelector[1], mainCallback)

  function observeDropdown(dropdownSelector, callback) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((record) => {
        const target = record.target

        if (target.classList.contains("filled")) {
          const field = target.closest(".system-settings__dropdown-field")
          const labelBtn = field?.querySelector(".system-settings-btn")

          if (labelBtn) labelBtn.classList.add("_visible")

          secondaryDropdownSelector.forEach((button) => {
            button.disabled = false
          })
          observer.disconnect()

          if (callback) callback()
        }
      })
    })

    observer.observe(dropdownSelector, config)
  }
}

export function handleSearch(event, type, context) {
  context.formState[type].items.forEach((item) => {
    const itemValue = item.querySelector(".js-list-item__value-text").innerText.toLowerCase()
    const isMatch = itemValue.includes(event.target.value.toLowerCase())
    item.classList[isMatch ? "remove" : "add"]("no-match")
  })
}

export function handleCheckboxChange(event, context) {
  context.formState[event.target.name].value = event.target.checked
  event.target.checked &&
    event.target.dataset.validation &&
    context.formState[event.target.name].parent.classList.remove("invalid")

  if (event.currentTarget.dataset.trigger) {
    const { trigger } = event.currentTarget.dataset
    const { checked } = event.currentTarget
    context.formState[trigger].trigger[checked ? "removeAttribute" : "setAttribute"]("disabled", "")
  }
}

export function handleShowPassword(event, context, inputName) {
  const iconShow = event.currentTarget.querySelector(".icon-eye")
  const iconClose = event.currentTarget.querySelector(".icon-eye-closed")
  iconShow.classList.toggle("active")
  iconClose.classList.toggle("active")
  const isHidden = iconShow.classList.contains("active")
  context.formState[inputName].input.type = isHidden ? "text" : "password"
}

export function handleValidPassword() {
  // to do js password validation
  return
}

export function handleInputForActivateDropdowns(event, triggers) {
  if (!event.target.value.length) {
    triggers.forEach((trigger) => trigger.setAttribute("disabled", true))
  } else {
    triggers.forEach((trigger) => trigger.removeAttribute("disabled"))
  }
}

export function checkValidationPasswordRules(input, form) {
  if (!form || !input) return;

  const { value } = input;
  const lengthCheck = value.length >= 8;
  const uppercaseCheck = /[A-Z]/.test(value);
  const numberCheck = /\d/.test(value);

  const rulesList = form.querySelectorAll(".js-validation-item");
  
  if (rulesList) {
    rulesList.forEach(rule => {
      const validationType = rule.getAttribute("data-validation");
      let isValid = false;

      switch (validationType) {
        case 'length':
          isValid = lengthCheck;
          break;
        case 'uppercase':
          isValid = uppercaseCheck;
          break;
        case 'number':
          isValid = numberCheck;
          break;
      }

      rule.classList.toggle("valid", isValid);
    });
  }
}