export const approvalRulesInputs = [
  {
    inputSelector: "#approval_rules_primary_approver",
    listPosition: "left",
  },
  {
    inputSelector: "#approval_rules_secondary_approver",
    listPosition: "right",
  },
  {
    inputSelector: "#approval_rules_primary_approver_editing_default",
    listPosition: "left",
  },
  {
    inputSelector: "#approval_rules_secondary_approver_editing_default",
    listPosition: "right",
  },
  {
    inputSelector: "#approval_rules_primary_approver_editing",
    listPosition: "left",
  },
  {
    inputSelector: "#approval_rules_secondary_approver_editing",
    listPosition: "right",
  },
]

export const addCreditCardInputs = [
  {
    inputSelector: "#add_card_primary_card_owner",
    listPosition: "left",
  },
  {
    inputSelector: "#add_card_secondary_card_owner",
    listPosition: "right",
  },
  {
    inputSelector: "#edit_card_primary_card_owner",
    listPosition: "left",
  },
  {
    inputSelector: "#edit_card_secondary_card_owner",
    listPosition: "right",
  },
]

export const userGroupsInputs = [
  {
    inputSelector: "#searchUser_editing",
    listPosition: "full-width",
  },
  {
    inputSelector: "#searchUser",
    listPosition: "full-width",
  },
]
