import { scrollbarLock, scrollbarUnlock } from "../../utils/scrollbar-locker"

export class EditSearchPopup {
  constructor() {
    this.popup = document.querySelector(".js-edit-search-popup")

    this.popupTriggers = document.querySelectorAll(".js-edit-search-popup-trigger")
    this.scrollTrigger = document.querySelector(".js-edit-search-popup-scrollTrigger")
    this.header = document.querySelector(".header")

    if(!this.popup) return

    this.needLock = this.popup.hasAttribute("data-needlock")

    this.init()
  }

  openEditPopup = () => {
    this.popup.classList.add("active")
    if(!this.needLock) return
    this.header.classList.add("upper-full")
    scrollbarLock()
  }

  closeEditPopup = () => {
    this.popup.classList.remove("active")
    const invalidFileds = this.popup.querySelectorAll(".searchbar-input-fieldparent.invalid")
    invalidFileds && invalidFileds.forEach(field => field.classList.remove("invalid"))
    if(!this.needLock) return
    this.header.classList.remove("upper-full")
    setTimeout(()=>{
      scrollbarUnlock()
    }, 100)
  }

  outsideEditPopupClick = (event) => { // outside close edit popup event
    if(!this.needLock) return
    if(event.target.classList.contains("js-edit-search-popup")) this.closeEditPopup()
  }

  windowScrollHandle = () => {
    if(!this.scrollTrigger) return
    const scrollPointDetector = this.scrollTrigger.getBoundingClientRect().top - this.header.offsetHeight
    if(scrollPointDetector <= 0){
      this.openEditPopup()
    }else this.closeEditPopup()
  }

  init() {
    this.popupTriggers && this.popupTriggers.forEach(trigger => {
      trigger.addEventListener('click', this.openEditPopup)
    })

    this.windowScrollHandle()
    if(!this.needLock) window.addEventListener("scroll", this.windowScrollHandle)

    window.addEventListener("click", this.outsideEditPopupClick)
  }
}