export default function makePopup() {
  const content = document.createElement('div')
  content.classList.add('popup-wrap')  

  const inner = `
      <div class="popup-img-wrap">
        <img src="./img/content/hotels-catalog/list_img_4.jpg" alt="hotel photo" width="236" height="168">
        <div class="save-banner">Save 45%</div>
      </div>

      <div class="popup-content">

        <div class="popup-content-title">Sole On The Ocean</div>

        <div class="popup-content-subtitle-wrap">
          <div class="stars">
              <svg class="icon icon-star filled">
                <use href="./icon/icons/icons.svg#star"></use>
              </svg>

              <svg class="icon icon-star filled">
                <use href="./icon/icons/icons.svg#star"></use>
              </svg>

              <svg class="icon icon-star filled">
                <use href="./icon/icons/icons.svg#star"></use>
              </svg>

              <svg class="icon icon-star filled">
                <use href="./icon/icons/icons.svg#star"></use>
              </svg>

              <svg class="icon icon-star ">
                <use href="./icon/icons/icons.svg#star"></use>
              </svg>
          </div>

          <div class="location">Miami Beach</div>

        </div>

        <div class="popup-content-amenities">

          <div class="popup-content-amenities-item">
              <svg class="icon icon-checkbox ">
                <use href="./icon/icons/icons.svg#checkbox"></use>
              </svg>
              Free Cancelation
          </div>
          <div class="popup-content-amenities-item">
              <svg class="icon icon-checkbox ">
                <use href="./icon/icons/icons.svg#checkbox"></use>
              </svg>
              Breakfast available
          </div>

        </div>

        <div class="popup-content-footer">

          <div class="popup-content-rating-review">
            <div class="rating">7.9</div>
            <div class="descr">Good</div>
          </div>

          <div class="popup-content-prices-wrap">
            <div class="popup-content-prices">
              <div class="old">$583</div>
              <div class="new">$290</div>
            </div>
            <div class="popup-content-prices-text">
              Nightly with taxes
            </div>
          </div>

        </div>

        <button type="button" class="popup-content-btn" aria-label="Select room">Select room</button>
      
      </div>
  `

  content.innerHTML = inner;
  return content
}