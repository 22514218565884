import { scrollbarLock, scrollbarUnlock } from "../../utils/scrollbar-locker"

export class TripsSidebar {
  constructor() {
    this.sidebar = document.querySelector(".js-trips-sidebar")
    this.triggers = document.querySelectorAll(".js-trips-sidebar__trigger")
    this.closeBtn = document.querySelector(".js-trips-sidebar__close-btn")

    if (!this.sidebar || !this.triggers) return

    this.init()
  }

  init = () => {
    this.triggers.forEach((trigger) => trigger.addEventListener("click", this.openSidebar))
    this.closeBtn.addEventListener("click", this.closeSidebar)
    window.addEventListener("click", (event) => this.handleClickOnBg(event))
  }

  openSidebar = () => {
    this.sidebar.classList.add("active")
    scrollbarLock()
  }

  closeSidebar = () => {
    this.sidebar.classList.remove("active")
    scrollbarUnlock()
  }

  handleClickOnBg = (event) => {
    const { target } = event
    if (target.classList.contains("trips-sidebar")) {
      this.sidebar.classList.remove("active")
      scrollbarUnlock()
    }
  }
}
