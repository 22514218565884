import { formEntireFormData } from "./helpers/helpers"

export default class CompanyInformationFormValidation {
  constructor(form) {
    this.form = form
    this.selects = form.querySelectorAll('.js-select')
    this.telInputs = form.querySelectorAll('.js-company-input')
    this.nameInputField = form.querySelector('.js-field')
    this.btn = form.querySelector('.js-company-btn')
    this.isEnableBtn = false

    // state
    this.formState = {} 

    this.#init()

    this.isFullForm = false
  }

  initTelInput = (input) => {
    input.addEventListener('input', this.handleTelInput)
  }

  handleTextInput = (event) => {
    const { value } = event.target
    this.formState.inputs["company-name"].value = value
    this.checkForFillForm()
  }

  handleSelect = (event) => {
    this.formState.selects[event.target.name].value = event.target.value
    this.checkForFillForm()
  }

  handleTelInput = (event) => {
    const { value, name: type } = event.target
    const code = this.formState.telInputs[type].dialCode
    this.formState.telInputs[type].value = code + value
    this.checkForFillForm()
  }

  handleDialCodeClick = (event) => {


    const {nodeName} = event.target
    const isLiClicked = nodeName === 'LI'
    const { dial_code } = isLiClicked ? event.target.dataset : event.target.closest('li').dataset
    const {name: inputName} = event.currentTarget.parentNode.previousElementSibling
    this.formState.telInputs[inputName].dialCode = dial_code


    // this.formState.telInputs[type].dialCode = code
    // this.formState.telInputs[type].value = code + this.formState.telInputs[type].input.value

    this.checkForFillForm()
  }

  checkForFillForm = () => {
    const selectsKeys = Object.keys(this.formState.selects)
    const telInputsKeys = Object.keys(this.formState.telInputs)
    const isInputNotEmpty = this.formState.inputs['company-name'].value !== '' 
    const isSelectsNotEmpty = selectsKeys.reduce((arr, key) => {
      if(!this.formState.selects[key].value.length) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    const isTelInpuntsNotEmpty = telInputsKeys.reduce((arr, key) => {
      if(!this.formState.telInputs[key].input.value.length || !this.formState.telInputs[key].dialCode.length) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    const phoneFormat = /^\d{10,},?\d*$/;

    const isTelInputsValid = telInputsKeys.reduce((arr, key) => {
      if(!phoneFormat.test(this.formState.telInputs[key].input.value)) {
        arr.push(true)
      } else {
        arr.push(false)
      }
      return arr
    }, []).every(item => item === false)

    this.isEnableBtn = isInputNotEmpty && isSelectsNotEmpty && isTelInpuntsNotEmpty && isTelInputsValid
    this.btn.disabled = !this.isEnableBtn

  }

  handleSubmit = (event) => {
    event.preventDefault()

    // fetch('somewhere', {
    //   method:  'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ ...this.formState })
    // })

    if (this.form.hasAttribute('data-href')) {
      const ftpDir = '/b/travlio/'
      setTimeout(() => {
        if(window.location.origin.includes("localhost")){
          window.location.href = `/${this.form.getAttribute('data-href')}`
        }else{
          window.location.href = `${ftpDir}${this.form.getAttribute('data-href')}`
        }
      }, 500)
    }

  }

  #init() {
    
    if (this.telInputs.length) {
      this.telInputs.forEach(this.initTelInput)
    }
    
    this.formState = {...formEntireFormData(this.selects, this.nameInputField, this.telInputs)}
    Object.keys(this.formState.telInputs).forEach(key => {
      this.formState.telInputs[key].countryList.addEventListener('click', this.handleDialCodeClick)
    })
    this.formState.inputs["company-name"].input.addEventListener('input', this.handleTextInput)

    const selectInputs = Object.keys(this.formState.selects).map(key => {
      return this.formState.selects[key].input
    })
    selectInputs.forEach(input => {
      input.addEventListener("change", (event) => {
        this.handleSelect(event)
      })
    })

    this.form.addEventListener('submit', this.handleSubmit)
  }
}