import { Toast } from "../../components/toast"
import { scrollbarLock, scrollbarUnlock } from "../../utils/scrollbar-locker"
import { SystemSettingsDefaultOffice } from "./modules/default-office"
import { SystemSettingsDynamicText } from "./modules/dinamic-change-text"
import { SystemSettingsPolicyItemRemove } from "./modules/remove-item-policy"
import { SystemSettingsSearchList } from "./modules/search-system-list"
import { GetAddress } from "./modules/offices-setup/office-address"

export class SystemSettings {
  constructor() {
    this.container = document.querySelector(".js-system-settings")
    if (!this.container) return

    this.defaultStartStep = "system-settings-preview"
    this.activeStep = this.defaultStartStep
    this.prevStep = this.defaultStartStep
    this.defaultBackHolder = "Back to"

    this.openSettingsTriggers = document.querySelectorAll(".js-trigger-info_sidebar")
    this.closeSettingsTriggers = document.querySelectorAll(".js-system-settings__close-btn")

    this.stepNextTriggers = document.querySelectorAll(".js-system-settings__step-trigger")
    this.stepBackTriggers = document.querySelectorAll(".js-system-settings__step-back-trigger")

    this.searchListInputs = this.container.querySelectorAll(".js-system-settings-list-search")
    this.officeSetupItems = this.container.querySelectorAll(".system-settings-offices-setup-step-item")
    this.dynamicTextInputs = this.container.querySelectorAll(".js-system-settings-dynamic-input input")
    this.modalRedirectBtns = document.querySelectorAll(".js-system-settings-modal-redirect-btn")

    this.#init()
  }

  openSystemSettings = () => {
    // open settings event
    this.container.classList.add("active")
    scrollbarLock()
    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((trigger) => {
        trigger.classList.add("active")
      })
    const activeSidenavLink = document.querySelector(".js-sidenavnav-link.active")
    if (activeSidenavLink) activeSidenavLink.classList.add("disable-action")
  }

  closeSystemSettings = () => {
    // close settings event
    this.container.classList.remove("active")
    scrollbarUnlock()
    setTimeout(this.changeStep, 200)
    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((trigger) => {
        trigger.classList.remove("active")
      })
    const activeSidenavLink = document.querySelector(".js-sidenavnav-link.active")
    if (activeSidenavLink) activeSidenavLink.classList.remove("disable-action")
  }

  changeStep = (activeStepName = this.defaultStartStep) => {
    this.activeStep == this.defaultStartStep
      ? (this.prevStep = this.defaultStartStep)
      : (this.prevStep = this.activeStep)
    this.activeStep = activeStepName
    const prevStepDiv = document.querySelector(`.js-system-settings__content[data-step='${this.prevStep}'].active`)
    prevStepDiv && prevStepDiv.classList.remove("active")
    const activeStepDiv = document.querySelector(`.js-system-settings__content[data-step='${this.activeStep}']`)
    activeStepDiv && activeStepDiv.classList.add("active")
  }

  setStepBackBtn = (backHolder = this.defaultBackHolder) => {
    const stepBackBtn = document.querySelector(
      `.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-header__backbtn`,
    )
    if (stepBackBtn) {
      stepBackBtn.setAttribute("data-step", this.prevStep)
      const valueHolder = stepBackBtn.querySelector(".value")
      if (!valueHolder) valueHolder.innerHTML = backHolder
    }
  }

  setFormRedirect = (redirectLink) => {
    const stepForm = document.querySelector(
      `.js-system-settings__content[data-step='${this.activeStep}'] .system-settings-form`,
    )
    if (stepForm) stepForm.setAttribute("data-redirect-step", redirectLink)
  }

  setNextStep = (activeStepName, backHolder, redirectLink = this.activeStep) => {
    // set active step
    const isActiveStep = document.querySelector(`.js-system-settings__content[data-step='${activeStepName}']`)
      ? true
      : false
    if (!isActiveStep) return
    this.changeStep(activeStepName)
    this.setStepBackBtn(backHolder)
    this.setFormRedirect(redirectLink)
  }

  stepNextTriggerClick = (stepNextTrigger) => {
    // change step next btn click event
    if (!stepNextTrigger.hasAttribute("data-step")) return

    if (stepNextTrigger.classList.contains("disable-step")) {
      if (stepNextTrigger.hasAttribute("data-toast"))
        this.showToast(stepNextTrigger.getAttribute("data-toast"), "error")
    } else {
      if (!this.container.classList.contains("active")) {
        scrollbarLock()
        this.container.classList.add("active")
      }
      if (stepNextTrigger.hasAttribute("data-back-holder")) {
        this.setNextStep(stepNextTrigger.getAttribute("data-step"), stepNextTrigger.getAttribute("data-back-holder"))
      } else this.setNextStep(stepNextTrigger.getAttribute("data-step"))
    }
  }

  stepBackTriggerClick = (stepBackTrigger) => {
    // change step back btn click event
    if (!stepBackTrigger.hasAttribute("data-step")) return
    this.changeStep(stepBackTrigger.getAttribute("data-step"))
  }

  showToast = (toastMessage = "Default message", toastStatus = "success") => {
    new Toast({ position: ".system-settings-content-box", text: toastMessage, type: toastStatus })
  }

  redirectTargetAction = (
    redirectName = this.defaultStartStep,
    backHolder = this.defaultBackHolder,
    redirectToTarget = this.prevStep,
  ) => {
    this.setNextStep(redirectName, backHolder, redirectToTarget)
  }

  redirectAction = (redirectName = this.defaultStartStep, toastMessage) => {
    this.changeStep(redirectName)
    this.showToast(toastMessage)
  }

  outsideSettingsClick = (event) => {
    // outside close settings event
    if (event.target.classList.contains("system-settings")) this.closeSystemSettings()
  }

  initSystemSettings = () => {
    // initial values and functions
    this.changeStep()

    this.openSettingsTriggers &&
      this.openSettingsTriggers.forEach((triggerSystem) => {
        triggerSystem.addEventListener("click", this.openSystemSettings)
      })
    this.closeSettingsTriggers &&
      this.closeSettingsTriggers.forEach((closeSystem) => {
        closeSystem.addEventListener("click", this.closeSystemSettings)
      })

    this.modalRedirectBtns &&
      this.modalRedirectBtns.forEach((modalBtn) => {
        modalBtn.addEventListener("click", () => {
          if (!modalBtn.hasAttribute("data-redirect-step")) return
          this.redirectAction(modalBtn.getAttribute("data-redirect-step"), modalBtn.getAttribute("data-toast"))
        })
      })
    this.stepNextTriggers &&
      this.stepNextTriggers.forEach((stepNextTrigger) => {
        stepNextTrigger.addEventListener("click", () => {
          this.stepNextTriggerClick(stepNextTrigger)
        })
      })
    this.stepBackTriggers &&
      this.stepBackTriggers.forEach((stepBackTrigger) => {
        stepBackTrigger.addEventListener("click", () => {
          this.stepBackTriggerClick(stepBackTrigger)
        })
      })

    this.searchListInputs &&
      this.searchListInputs.forEach((searchInput) => {
        new SystemSettingsSearchList(searchInput)
      })
    this.officeSetupItems &&
      this.officeSetupItems.forEach((officeItem) => {
        new SystemSettingsDefaultOffice(officeItem)
      })
    this.dynamicTextInputs &&
      this.dynamicTextInputs.forEach((dynamicInput) => {
        new SystemSettingsDynamicText(dynamicInput)
      })
    new SystemSettingsPolicyItemRemove()

    window.addEventListener("click", this.outsideSettingsClick)
  }

  #init() {
    this.initSystemSettings()
  }
}
window.addEventListener("load", () => {
  new GetAddress()
});


export const systemSettings = new SystemSettings()
