import $ from "jquery"
import { scrollbarLock, scrollbarUnlock } from "../utils/scrollbar-locker"
import { rangePicker } from "../lib/range-picker"

const datePickers = () => {
  rangePicker()

  let datePickerInputs = document.querySelectorAll(".datepicker-input")
  datePickerInputs.forEach(datePicker=>{
    datePicker.addEventListener("focus",() => {datePicker.blur()})
    datePicker.addEventListener("keydown", (e) => {e.preventDefault();});

    const isInModal = datePicker.closest(".modal__wrapper")

    const dateStartInput = datePicker.parentNode.querySelector(".datepicker-input-startdate")
    const dateEndInput = datePicker.parentNode.querySelector(".datepicker-input-enddate")

    // set min date
    let minDate
    if(datePicker.hasAttribute("data-start-range")){
      minDate = datePicker.getAttribute("data-start-range")
    }else{
      minDate = new Date();
      minDate.toString("mm-dd-YY");
    }
    // set min date end

    // default init rage value
    if(datePicker.classList.contains("day-range-default")){
      const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
      const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      Date.prototype.getMonthName = function() {
        return months[ this.getMonth() ];
      };
      Date.prototype.getDayName = function() {
        return days[ this.getDay() ];
      };
    
      const todayDate = new Date();
      const tomorrowDate = new Date(todayDate)
      tomorrowDate.setDate(todayDate.getDate() + 1)
  
      const todayDay = todayDate.getDate()
      const todayWeek = todayDate.getDayName();
      const todayMonth = todayDate.getMonthName();
      const todayYear = todayDate.getFullYear().toString().substr(-2);
      const tomorrowDay = tomorrowDate.getDate()
      const tomorrowWeek = tomorrowDate.getDayName();
      const tomorrowMonth = tomorrowDate.getMonthName();
      const tomorrowYear = tomorrowDate.getFullYear().toString().substr(-2);
      
      datePicker.value = `${todayWeek}, ${todayMonth} ${todayYear} - ${tomorrowWeek}, ${tomorrowMonth} ${tomorrowYear}`
      if(dateStartInput) dateStartInput.value = `${todayWeek}, ${todayMonth} ${todayYear}`
      if(dateEndInput) dateEndInput.value = `${tomorrowWeek}, ${tomorrowMonth} ${tomorrowYear}`
    }
    // default init rage value end

    // set autoclose according window size
    let autoCloseVariable = false
    if(window.innerWidth > 576) autoCloseVariable = true
    // set autoclose according window size end

    $(datePicker).dateRangePicker({
      separator: ' - ',
      singlemonth: false,
      time: {
        enabled: true
      },
      format: "ddd, MMM YY",
      startDate: minDate,
      language: 'en',
      autoClose: autoCloseVariable,
      stickyMonths : true,
      showTopbar: true,
      customArrowPrevSymbol: '<svg class="icon icon-arrow-left "><use href="./icon/icons/icons.svg#left"></use></svg>',
      customArrowNextSymbol: '<svg class="icon icon-arrow-left "><use href="./icon/icons/icons.svg#right"></use></svg>',
      customOpenAnimation: function(cb) {
        $(this).fadeIn(200, cb);
        if(datePicker.hasAttribute("data-popup")) this.classList.add("modal-datepicker-popup") 
        if(!isInModal){
          if(datePicker.hasAttribute("data-needlock") || window.innerWidth < 577) scrollbarLock()
        }
      },
      customCloseAnimation: function(cb) {
        $(this).fadeOut(200, cb);
        setTimeout(() => {
          if(datePicker.hasAttribute("data-popup")) this.classList.remove("modal-datepicker-popup") 
          if(!isInModal){
            if(datePicker.hasAttribute("data-needlock") || window.innerWidth < 577) scrollbarUnlock()
          }
        }, 210)
      },
    }).bind('datepicker-open',function(){ 
      datePicker.classList.add("datepicker-input-active")
    }).bind('datepicker-close', function(){
      datePicker.classList.remove("datepicker-input-active")
    }).bind('datepicker-change', function(event, object){
      let eventInput = new Event("input")
      datePicker.dispatchEvent(eventInput)
      const dates = object.value.split(" - ");
      if(dateStartInput) dateStartInput.value = dates[0]
      if(dateEndInput) dateEndInput.value = dates[1]
    })
  })

  const datepickerTriggerBtns = document.querySelectorAll(".js-date-trigger")
  datepickerTriggerBtns && datepickerTriggerBtns.forEach(triggerDateBtn => {
    
    const event = new Event("click")
    const dropdownParent = triggerDateBtn.closest(".dropdown.js-select")
    if(dropdownParent){
      const inputPicker = dropdownParent.querySelector(".datepicker-input")
      if(!inputPicker) return
      triggerDateBtn.addEventListener("click", ()=> {
        // $(inputPicker).data('dateRangePicker').clear();
        setTimeout(() => {
          inputPicker.dispatchEvent(event)
        }, 100)
      })
      
      inputPicker.addEventListener('input', ()=> {
        const dropdownHolder = dropdownParent.querySelector(".dropdown__trigger-value")
        if(!dropdownHolder) return
        dropdownHolder.innerHTML = inputPicker.value
        dropdownParent.classList.add("_chosen")
        const dropdownTrigger = dropdownParent.querySelector(".dropdown__trigger")
        if(dropdownTrigger) dropdownTrigger.dispatchEvent(event)
        const pastActiveOption = dropdownParent.querySelector(".js-select-dropdown ._selected")
        if(pastActiveOption) pastActiveOption.classList.remove("_selected")
      })
    }
  })
}

export{
  datePickers
}
