import { loaderSearchModal } from "../loader-modal";

export const searchbarValidation = (searchbarFormConatiner) => {
  const searhbarForm = searchbarFormConatiner
  if(!searhbarForm) return;

  const searchbarRequireFields = searhbarForm.querySelectorAll(".searchbar-input-field.required")
  
  const setValidStatus = (field) => {
    const fieldParent = field.closest('.searchbar-input-fieldparent');
    if(!fieldParent) return;
    if(!field.value){
      fieldParent.classList.add('invalid');
      const dropdownTravalers = fieldParent.parentNode.querySelector(".js-travalers-settings-dropdown")
      if(dropdownTravalers) dropdownTravalers.classList.add("invalid-search")
    }else{
      fieldParent.classList.remove('invalid');
    }
  }

  const removeInvalid = (field) => {
    const fieldParent = field.closest('.searchbar-input-fieldparent');
    if(!fieldParent) return;
    fieldParent.classList.remove('invalid');
  }

  searchbarRequireFields && searchbarRequireFields.forEach(requireField => {
    requireField.oninput = () => removeInvalid(requireField)
  })

  searhbarForm.addEventListener("submit", (e) => {
    e.preventDefault();
    searchbarRequireFields && searchbarRequireFields.forEach(requireField => {
      setValidStatus(requireField)
    })

    let errorLength = searhbarForm.querySelectorAll(".searchbar-input-fieldparent.invalid").length
    if(errorLength){
      // if not valid
      console.warn("Validation searchbar error!")
    }else{ 
      // if valid
      const searchbarEditPopup = searhbarForm.closest(".js-edit-search-popup.active")
      const header = document.querySelector(".header")
      if(searchbarEditPopup && searchbarEditPopup.hasAttribute("data-needlock")) searchbarEditPopup.classList.remove("active") 
      if(header) header.classList.remove("upper-full")

      loaderSearchModal.showLoader()
      let dataForm = new FormData(searhbarForm)
      fetch('https://jsonplaceholder.typicode.com/posts', {
        method: 'POST',
        body: dataForm
      })
        .then(function(response) {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then(function(data) {
          console.log(data)
          setTimeout(()=>{ // timeout here need to show modal (for regular use it`s not necessary)
            loaderSearchModal.hideLoader()
          },2000)
        })
        ['catch'](function(error) {
          console.warn(error);
      });
    }
  })
}