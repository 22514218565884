export class UploadAvatar {
  constructor(wrap) {
    this.wrap = wrap
    this.imgWrap = wrap.querySelector('.js-user-settings-upload-avatar-img-wrap')
    this.avatarImg = wrap.querySelector('.js-user-settings-upload-avatar-img')
    this.dragoverContainer = wrap.querySelector('.js-user-settings-upload-avatar-drag-wrap')
    this.input = wrap.querySelector('.js-user-settings-upload-avatar-input')
    this.#init()
  }

  handleInput = (event, img, imgWrap) => {
    const droppedFiles = event.target.files;

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];

      if (file.type.match('image.*')) {
        const reader = new FileReader();

        reader.onload = function (readerEvent) {
          const droppedImageSrc = readerEvent.target.result;
          img.src = droppedImageSrc
          imgWrap.classList.add('loaded')
        };
        reader.readAsDataURL(file);
      }
    }
  }

  dragOver = (event) => {
    event.preventDefault()
    this.dragoverContainer.classList.add('dragover')
  }
  dragEnd = () => {
    this.dragoverContainer.classList.remove('dragover')
  }
  drop = (event, img, imgWrap) => {
    event.preventDefault();

    const droppedFiles = event.dataTransfer.files;

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];

      if (file.type.match('image.*')) {
        const reader = new FileReader();

        reader.onload = function (readerEvent) {
          const droppedImageSrc = readerEvent.target.result;
          img.src = droppedImageSrc
          imgWrap.classList.add('loaded')
        };
        reader.readAsDataURL(file);
      }
    }
    this.dragoverContainer.classList.remove('dragover')
  }

  #init() {
    this.input && this.input.addEventListener('change', (event) => this.handleInput(event, this.avatarImg, this.imgWrap))
    this.dragoverContainer.addEventListener('dragover', this.dragOver)
    this.dragoverContainer.addEventListener('dragleave', this.dragEnd)
    this.dragoverContainer.addEventListener('drop', (event) => this.drop(event, this.avatarImg, this.imgWrap))
  }
}