import { Toast } from "../../../components/toast"

export class SystemSettingsDefaultOffice {
  constructor(itemOffice) {
    this.officeSetupItem = itemOffice
    if(!this.officeSetupItem) return
    this.setDefaultBtn = this.officeSetupItem.querySelector(".system-settings-set-btn")
    if(!this.setDefaultBtn) return

    this.init()
  }

  setDefaultOffice = () => {
    const parentOfficeList = this.officeSetupItem.closest(".system-settings-list")
    if(!parentOfficeList) return
    const activeOffice = parentOfficeList.querySelector(".system-settings-offices-setup-step-item._default")
    if(!activeOffice) return
    activeOffice.classList.remove("_default")
    this.officeSetupItem.classList.add("_default")
    if(this.officeSetupItem.hasAttribute("data-toast")){
      new Toast({ position: ".system-settings-content-box", text: this.officeSetupItem.getAttribute("data-toast"), type: "success" })
    }
  }

  init() {
    this.setDefaultBtn.addEventListener('click', this.setDefaultOffice)
  }
}