import {
  formPhoneInputData,
  handleDropdownBackdropClick,
  initFormState,
  initValidationFieldsState,
  setDropdownsData,
  clearFields,
  handleSubmit,
  initInput,
  initTelInput,
  initDropdown,
  setCardData,
  setCheckboxesData,
  handleCheckboxChange,
} from "./helpers/helpers.js"

class FormValidation {
  constructor(form) {
    this.form = form
    this.inputs = form.querySelectorAll(".js-system-settings-input")
    this.telInputs = form.querySelectorAll('input[type="tel"]')
    this.checkboxes = form.querySelectorAll(".js-system-settings-form__group__default-checkbox")
    this.validationInfoNode = form
      .closest(".system-settings-form__wrap")
      ?.previousElementSibling.querySelector(".js-isvalid-form-info")
    this.overflowWrapper = form.closest(".js-system-settings__content-main")
    this.dropdowns = form.querySelectorAll(".js-system-settings__dropdown-wrap")
    this.cancelBtn = form.querySelector(".js-system-settings-form-cancel")
    this.isScrollToError = !this.validationInfoNode
    this.submitBtn = form.querySelector('.js-system-settings-form__submit')

    this.formState = {}
    this.validationFieldsState = {}

    if (this.form.dataset.card) {
      this.cardInput = form.querySelector('.js-system-settings-input[data-type="card"]')
      this.cardOutput = form.querySelector(".js-card-holder")
      setCardData(this.cardInput, this.cardOutput, this)
    }

    this.validationState = null
    this.isValid = false

    this.inputs && this.inputs.length && this.inputs.forEach((input) => initFormState(input, this))
    this.telInputs.length && this.telInputs.forEach((input) => (this.formState[input.name] = formPhoneInputData(input)))
    this.dropdowns.length && setDropdownsData(this)
    this.checkboxes.length && this.checkboxes.forEach((checkbox) => setCheckboxesData(checkbox, this))

    initValidationFieldsState(this)

    this.#init()
  }

  #init() {
    if (this.inputs.length) {
      this.inputs.forEach((input) => initInput(input, this))
    }

    if (this.telInputs.length) {
      this.telInputs.forEach((input) => initTelInput(input, this))
    }

    if (this.dropdowns.length) {
      this.dropdowns.forEach((dropdown) => initDropdown(dropdown, this))

      window.addEventListener("click", (event) => {
        handleDropdownBackdropClick(this, event)
      })
    }

    if (this.checkboxes.length) {
      this.checkboxes.forEach((checkbox) =>
        checkbox.addEventListener("change", (event) => handleCheckboxChange(event, this)),
      )
    }

    this.form.addEventListener("submit", (event) => handleSubmit(event, this))
    this.cancelBtn && this.cancelBtn.addEventListener("click", () => clearFields(this))
  }
}

export default FormValidation
