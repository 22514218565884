import { updateFormState, updateValidationState } from "./_state-actions"
import { setCheckboxGroupData } from "./_setters"
import { redirectBySubmit } from "./_redirect"
import { checkViewportAndScroll, checkFirstErrorToScroll, setErrors, isFormValid } from "./_validation-actions"

export const handleSubmit = (event, context) => {
  event.preventDefault()
  updateFormState(context)
  updateValidationState(context)
  isFormValid(context)
  // console.log(context);
  if (context.isValid) {
    context.checkboxesGropus &&
      context.checkboxesGropus.length &&
      context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))

    // fetch('localhost...', {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({ ...context.formState })
    // })
    // console.log("valid", context.formState)
    const dataForServer = {
      ...Object.keys(context.formState).reduce((obj, key) => {
        if (context.formState[key].type === "checkboxes") {
          console.log(key)
          context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))
          const checkKeys = Object.keys(context.formState[key])

          const checkboxesData = checkKeys.reduce((obj, checkKey) => {
            if (context.formState[key][checkKey].checkbox) {
              return { ...obj, [checkKey]: context.formState[key][checkKey].value }
            } else {
              return obj
            }
          }, {})
          console.log("checkboxesData", checkboxesData)
          return { ...obj, ...checkboxesData }
        } else {
          const updatedObj = {
            ...obj,
            [key]: context.formState[key].type === "tel"
              ? context.formState[key].full_value
              : context.formState[key].type === 'select_user'
                ? context.formState[key].input.dataset.id
                : context.formState[key]?.default_email?.length
                  ? context.formState[key].value + context.formState[key].default_email
                  : context.formState[key].value,
          };
          
          if (context.formState[key].type === "tel") {
            updatedObj[key + "_tel_code"] = context.formState[key].codeValue;
            updatedObj[key + "_tel_body"] = context.formState[key].value;
            updatedObj[key + "_tel_country"] = context.formState[key].countryValue;
          }
          
          return updatedObj;
        }
      }, {}),
    }
    // console.log(context.formState)
    console.log("submited dataForServer", dataForServer)
    redirectBySubmit(context)
  } else {
    setErrors(context)
    context.validationInfoNode && checkViewportAndScroll(context.validationInfoNode, context.overflowWrapper)
    context.isScrollToError && checkFirstErrorToScroll(context, context.overflowWrapper)
    context.checkboxesGropus &&
      context.checkboxesGropus.length &&
      context.checkboxesGropus.forEach((group) => setCheckboxGroupData(group, context))
  }
}
